import { cn } from "@web/lib/utils"
import { ChevronRight } from "lucide-react"

const RANK_COLORS = {
	// 0: "bg-[#2941f3]",
	1: "bg-[#d39318]",
	2: "bg-[#919191]",
	3: "bg-[#9d8156]",
	4: "bg-[#aab2fa]",
}

interface PrizeRankProps {
	rank: number
	isActive: boolean
	prizeModalInfo: {
		info: string
		slides: {
			header: string
			content: string
			imageSrc: string
			startIndex: number
			endIndex: number
		}[]
	}
}

export function PrizeRank({ rank, isActive, prizeModalInfo }: PrizeRankProps) {
	const lastRank = Math.max(...prizeModalInfo.slides.map((info) => info.endIndex))

	if (!isActive || !prizeModalInfo || rank > lastRank) {
		return (
			<div className="ml-5 translate-y-[.05rem] py-[1.3rem] text-left font-mono text-[#3d3d42]/80 text-xs">
				{rank.toString().padStart(2, "0")}
			</div>
		)
	}

	if (prizeModalInfo.info && rank === 0) {
		return (
			<div className="relative block h-full">
				<div className="absolute inset-0 bg-[#2941f3]/10" />
				<div className="-left-[.2rem] -translate-y-1/2 absolute top-1/2 h-8 w-2 rounded-full bg-[#2941f3]" />

				<div
					className="ml-5 translate-y-[.05rem] py-[.5rem] text-left text-[#3d3d42]/80"
					// biome-ignore lint/security/noDangerouslySetInnerHtml: prizeMessage is dynamically set from the db
					dangerouslySetInnerHTML={{ __html: prizeModalInfo.info }}
				/>

				<ChevronRight className="-translate-y-1/2 absolute top-1/2 right-1 size-6 text-blue-primary" />
			</div>
		)
	}

	if (prizeModalInfo && rank > 0) {
		return (
			<div className="relative block h-full">
				<div
					className="absolute inset-0"
					style={{
						background: `linear-gradient(to right, ${RANK_COLORS[Math.min(rank, 4) as keyof typeof RANK_COLORS]?.replace("bg-[", "").replace("]", "")}26, transparent)`,
					}}
				/>
				<div
					className={cn("-left-[.2rem] -translate-y-1/2 absolute top-1/2 h-11 w-2 rounded-full", {
						[RANK_COLORS[rank as keyof typeof RANK_COLORS]]: rank <= 3,
						[RANK_COLORS[4]]: rank > 3,
					})}
				/>

				<div className="ml-5 translate-y-[.05rem] py-[1.3rem] text-left font-mono text-[#3d3d42]/80 text-xs">
					{rank.toString().padStart(2, "0")}
				</div>
			</div>
		)
	}

	return null
}
