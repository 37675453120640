import { createFileRoute } from "@tanstack/react-router"
import { Loading } from "@web/components/shared/Loading"
import { CollectivesTable } from "@web/components/tables/CollectivesTable"
import { collectivesQueryOptions, useCollectives } from "@web/hooks/queries/useCollectives"
import { contractsQueryOptions, useContracts } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug/")({
	component: ContractRoute,
	loader: ({ params, context: { queryClient } }) => {
		const { contractSlug } = params
		queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
		queryClient.ensureQueryData(collectivesQueryOptions(queryClient, contractSlug))
	},
})

export function ContractRoute() {
	const { contractSlug } = Route.useParams()
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { data: collectives = [] } = useCollectives(contractSlug)

	if (!contract || !collectives) return <Loading />

	return (
		<>
			<CollectivesTable collectives={collectives} />
		</>
	)
}
