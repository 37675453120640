import { TransparentBox } from "@web/components/faqs/TransparentBox"

interface TimelineBlockProps {
	timelineFAQs: { question: string; answer: string }[]
	className?: string
}

export function TimelineBlock({ timelineFAQs, className }: TimelineBlockProps) {
	return (
		<div className={className}>
			<div className="mb-1 flex items-baseline justify-between">
				<h1 className="font-semibold text-[1.25rem]">TIMELINE</h1>
			</div>
			<TransparentBox className="w-full" innerClassName="px-[1.2rem] py-[.9rem] flex-col">
				{timelineFAQs.map(({ question, answer }, index) => (
					<div key={question}>
						{index !== 0 && (
							<div
								className="mx-auto my-3 w-full border border-white opacity-20"
								style={{
									borderWidth: 0.5,
								}}
							/>
						)}
						<h1 className="font-bold">{question}</h1>
						<p>{answer}</p>
					</div>
				))}
			</TransparentBox>
		</div>
	)
}
