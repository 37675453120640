import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { useNavigate } from "@tanstack/react-router"
import { useToast } from "@web/hooks/useToast"
import { LoginContext } from "@web/providers/LoginProvider"
import { usePostHog } from "posthog-js/react"
import { useContext } from "react"

export function useLoginContext() {
	const posthog = usePostHog()
	const navigate = useNavigate()
	const { showError } = useToast()
	const { setShowAuthFlow, handleLogOut } = useDynamicContext()

	const context = useContext(LoginContext)
	if (context === null) {
		throw new Error("useLoginContext() was used outside of LoginProvider")
	}

	async function login() {
		if (context?.isLoggedIn) {
			await logout()
			showError("Error: Please try logging in again")
		} else {
			setShowAuthFlow(true)
		}
	}

	async function logout() {
		await handleLogOut()

		posthog.reset()
		Sentry.setUser(null)
		navigate({ to: "/" })
	}

	return {
		login,
		logout,
		...context,
	}
}
