import type { AppType } from "@api/index"
import { getAuthToken } from "@dynamic-labs/sdk-react-core"
import { hc } from "hono/client"

export const client = hc<AppType>("", {
	headers: (): Record<string, string> => {
		const token = getAuthToken()
		return token ? { Authorization: `Bearer ${token}` } : {}
	},
})
