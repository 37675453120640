import { useSearch } from "@tanstack/react-router"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { Checkbox } from "@web/components/ui/checkbox"
import { DrawerContent, DrawerFooter, DrawerHeader } from "@web/components/ui/drawer"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { shorten } from "@web/lib/utils"
import { Modal } from "@web/types"
import { Copy } from "lucide-react"
import { useState } from "react"
import { toast } from "sonner"

const events = {
	deposit: "onboard_modal:deposit_button_click",
	cancel: "onboard_modal:cancel_button_click",
	faqs: "onboard_modal:faqs_button_click",
}

export default function DepositModal() {
	const analytics = useAnalytics(events)
	const { openModal } = useModal()
	const { fanId } = useLoginContext()

	const search = useSearch({ from: "__root__" })
	const { tokenSlug = "degen" } = search

	const {
		data: [token] = [],
	} = useTokens(tokenSlug)
	const { tokenImageSrc, ticker, color, url } = token ?? {}

	const [hasConfirmed, setHasConfirmed] = useState(false)

	function handleClickFAQs() {
		analytics.faqs()
		openModal(Modal.FAQs)
	}

	function handleClickCopy() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(fanId as `0x${string}`)
			toast.success("Your address was copied to clipboard!")
		} else {
			toast.error("Something went wrong")
		}
	}

	if (!fanId || !token) {
		return (
			<DrawerContent className="min-h-72">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<div className="-mt-8 size-16 overflow-hidden rounded-full border-4 border-white">
				<img src={tokenImageSrc ?? ""} alt={`Symbol for ${ticker}`} className="size-14" />
			</div>

			<DrawerHeader>
				<div className="flex items-center">
					<span className="-mt-[.25rem] mb-[.69rem] font-semibold">Send {ticker} to Your Account</span>
				</div>
			</DrawerHeader>

			<div className="flex flex-col px-9">
				<p className="mb-2 ml-0 text-center text-gray-primary leading-[1.33rem]">
					To increase your available balance, you can send{" "}
					<a href={url ?? ""} rel="noreferrer" target="_blank" className="opacity-100" style={{ color: color }}>
						{ticker}
					</a>{" "}
					<strong>(Base)</strong> to your Bracket account from an external wallet. Here's how:
				</p>

				<ol className="mt-4 list-outside list-decimal space-y-4 px-3 text-gray-primary leading-[1.33rem]">
					<li>
						<strong>Confirm</strong> that you understand the above.
					</li>
					<li>
						<strong>Copy</strong> your Bracket address and <strong>send</strong>{" "}
						<a href={url ?? ""} rel="noreferrer" target="_blank" className="opacity-100" style={{ color: color }}>
							{ticker}
						</a>{" "}
						to it from a<strong> Base compatible wallet.</strong>
					</li>
				</ol>

				<div className="mx-auto mt-[1.62rem] flex items-center space-x-[.5rem] text-gray-primary">
					<Checkbox
						id="confirm"
						checked={hasConfirmed}
						onCheckedChange={() => setHasConfirmed(!hasConfirmed)}
						className="size-5 border-none bg-[#D9D9D9] checked:bg-[#D9D9D9]"
					/>
					<label htmlFor="confirm" className={`${hasConfirmed ? "text-black" : ""}`}>
						Confirm, I understand
					</label>
				</div>

				<Button
					variant="disappear"
					disabled={!hasConfirmed}
					onClick={handleClickCopy}
					className="-mb-1 mt-5 font-mono font-normal text-[.9375rem]"
					style={{ color: hasConfirmed ? color : "#6c6d75" }}
				>
					{shorten(fanId as `0x${string}`)}
					<Copy className="-mr-[.5rem] ml-[.5rem] size-[1.11rem]" style={{ color: hasConfirmed ? color : "#6c6d75" }} />
				</Button>
			</div>

			<DrawerFooter className="my-1 mt-2 flex-row gap-0 text-center text-gray-link text-sm">
				<span>To learn more about Bracket, visit</span>
				<button
					type="button"
					onClick={handleClickFAQs}
					className="ml-1 font-medium underline decoration-text-gray-link underline-offset-[5px]"
				>
					Bracket 101
				</button>
			</DrawerFooter>
		</DrawerContent>
	)
}
