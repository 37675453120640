import { useNavigate } from "@tanstack/react-router"
import { AvatarFallback, AvatarImage, Avatar as AvatarRoot } from "@web/components/ui/avatar"
import { Modal } from "@web/types"
import { default as BoringAvatar } from "boring-avatars"
import type { Address } from "viem"

const BASE_COLORS = ["#6194FE", "#9FBEFE", "#0B59FF", "#EFF4FE", "#2168FE"]

interface AvatarProps {
	src?: string | null
	alt: string
	fanId?: Address
	acronym?: string
	primaryColor?: string | null
	secondaryColor?: string | null
	className?: string
}

export function Avatar(props: AvatarProps) {
	const navigate = useNavigate({ from: "/" })

	function handleClick(e: React.MouseEvent<HTMLDivElement>) {
		e.preventDefault()
		if (!props.fanId) return
		navigate({ search: { modal: Modal.Fan, fanId: props.fanId } })
	}

	return (
		<AvatarRoot className={props.className} onClick={handleClick}>
			<AvatarImage src={props.src ?? undefined} alt={props.alt} className="block object-cover object-center" />

			{/* Fallback for image avatars */}
			{props.src && <AvatarFallback className="animate-pulse bg-zinc-200" />}

			{/* Fallback for acronym avatars */}
			{!props.src && (
				<AvatarFallback style={{ backgroundColor: props.primaryColor ?? "#fffff" }}>
					{/* If an acronym is provided, use it */}
					{props.acronym && !props.src && (
						<span className="font-bold uppercase italic" style={{ color: props.secondaryColor ?? "#ffffff" }}>
							{props.acronym}
						</span>
					)}

					{/* If no acronym is provided, use the Boring Avatar */}
					{!props.acronym && !props.src && <BoringAvatar size="100%" colors={BASE_COLORS} name={props.alt} />}
				</AvatarFallback>
			)}
		</AvatarRoot>
	)
}
