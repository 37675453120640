import { type ExternalToast, toast } from "sonner"

export function useToast() {
	function showToast(message: string, data?: ExternalToast) {
		toast(message, data)
	}

	function showSuccess(message: string, error?: unknown) {
		toast.success(message)
		console.info(`Success: ${message}`)

		// Show full error in development mode
		if (import.meta.env.DEV && error) {
			console.info(error)
		}
	}

	function showInfo(message: string, error?: unknown) {
		toast.info(message)
		console.info(`Info: ${message}`)

		// Show full error in development mode
		if (import.meta.env.DEV && error) {
			console.info(error)
		}
	}

	function showWarning(message: string, error?: unknown) {
		toast.warning(message)
		console.warn(`Warning: ${message}`)

		// Show full error in development mode
		if (import.meta.env.DEV && error) {
			console.warn(error)
		}
	}

	function showError(message: string, error?: unknown) {
		toast.error(message)
		console.error(`Error: ${message}`)

		// Show full error in development mode
		if (import.meta.env.DEV && error) {
			console.error(error)
		}
	}

	return {
		showToast,
		showSuccess,
		showInfo,
		showWarning,
		showError,
	}
}
