import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react"
import * as React from "react"

import { type ButtonProps, buttonVariants } from "@web/components/ui/button"
import { cn } from "@web/lib/utils"

const PaginationRoot = ({ className, ...props }: React.ComponentProps<"nav">) => (
	<nav aria-label="pagination" className={cn("mx-auto flex w-full justify-center", className)} {...props} />
)
PaginationRoot.displayName = "PaginationRoot"

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<"ul">>(
	({ className, ...props }, ref) => (
		<ul ref={ref} className={cn("flex flex-row items-center gap-1", className)} {...props} />
	),
)
PaginationContent.displayName = "PaginationContent"

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<"li">>(({ className, ...props }, ref) => (
	<li ref={ref} className={cn("", className)} {...props} />
))
PaginationItem.displayName = "PaginationItem"

type PaginationLinkProps = {
	isActive?: boolean
	isNewestOrOldest?: boolean
} & Pick<ButtonProps, "size"> &
	React.ComponentProps<"a">

const PaginationLink = ({ className, isActive, isNewestOrOldest, size = "icon", ...props }: PaginationLinkProps) => (
	<a
		aria-current={isActive ? "page" : undefined}
		className={cn(
			buttonVariants({
				variant: isNewestOrOldest ? "disappear" : isActive ? "outline" : "ghost",
				size,
			}),
			className,
			"cursor-pointer ",
		)}
		{...props}
	/>
)
PaginationLink.displayName = "PaginationLink"

const PaginationFirst = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
	<PaginationLink
		aria-label="Go to previous page"
		size="default"
		isNewestOrOldest={true}
		className={cn("pl-1", className)}
		{...props}
	>
		<ChevronLeft className="mt-[.1rem] size-[1.1rem] opacity-70" />
		<span>Newest</span>
	</PaginationLink>
)
PaginationFirst.displayName = "PaginationFirst"

const PaginationLast = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
	<PaginationLink
		aria-label="Go to next page"
		size="default"
		isNewestOrOldest={true}
		className={cn("pr-2.5", className)}
		{...props}
	>
		<span>Oldest</span>
		<ChevronRight className="mt-[.1rem] size-[1.1rem] opacity-70" />
	</PaginationLink>
)
PaginationLast.displayName = "PaginationLast"

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<"span">) => (
	<span aria-hidden className={cn("flex h-9 w-9 items-center justify-center", className)} {...props}>
		<MoreHorizontal className="h-4 w-4" />
		<span className="sr-only">More pages</span>
	</span>
)
PaginationEllipsis.displayName = "PaginationEllipsis"

export {
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationLink,
	PaginationLast,
	PaginationFirst,
	PaginationRoot,
}
