import { useNavigate, useSearch } from "@tanstack/react-router"
import FarcasterIcon from "@web/assets/farcaster.svg?react"
import TwitterIcon from "@web/assets/twitter.svg?react"
import { Handle } from "@web/components/modals/ui/Handle"
import { ActivityFeed } from "@web/components/shared/ActivityFeed"
import { Avatar } from "@web/components/shared/Avatar"
import { Loading } from "@web/components/shared/Loading"
import { TabUnderline } from "@web/components/shared/TabUnderline"
import { Username } from "@web/components/shared/Username"
import { CollectivesTable } from "@web/components/tables/CollectivesTable"
import { DrawerContent, DrawerHeader } from "@web/components/ui/drawer"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@web/components/ui/tabs"
import { useFan } from "@web/hooks/queries/useFan"
import { useHoldings } from "@web/hooks/queries/useHoldings"
import { useAnalytics } from "@web/hooks/useAnalytics"
import type { Filter } from "@web/types"
import type { Address } from "viem"

const events = {
	filter: "fan_profile:filter_click",
}

export default function FanModal() {
	const analytics = useAnalytics(events)
	const navigate = useNavigate({ from: "/" })

	const search = useSearch({ from: "__root__" })
	const { fanId, filter = "holdings" } = search

	const {
		data: [fan] = [],
	} = useFan(fanId as Address)
	const { username, fanImageSrc, provider } = fan ?? {}
	const tabStyling = "data-[state=active]:font-bold data-[state=active]:text-black data-[state=active]:bg-transparent"

	const { data: holdings = [] } = useHoldings(fanId as Address)

	function handleFilterChange(filter: string) {
		analytics.filter({ filter })
		navigate({
			search: (prev) => ({ ...prev, filter: filter as Filter }),
		})
	}

	if (!fanId || !username) {
		return (
			<DrawerContent className="min-h-72">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<Handle />

			<DrawerHeader className="mt-1 flex w-full items-center py-0">
				<div className="relative">
					<Avatar src={fanImageSrc} alt={`Avatar of ${username}`} className="relative z-10 h-[3.8rem] w-[3.8rem]" />
				</div>

				<div className="ml-2 flex flex-col">
					<div className="flex items-center">
						<Username username={username} provider={provider} className="-mb-1 -mt-1 ml-1 scale-[1.15]" />

						{provider === "farcaster" && (
							<FarcasterIcon className="ml-2 h-[.98rem] w-[.98rem] fill-current text-gray-link/40 transition-all hover:text-gray-link" />
						)}
						{provider === "twitter" && (
							<TwitterIcon className="ml-2 h-[.98rem] w-[.98rem] fill-current text-gray-link/40 transition-all hover:text-gray-link" />
						)}
					</div>
				</div>
			</DrawerHeader>

			<Tabs value={filter} onValueChange={handleFilterChange} className="mt-2 flex w-full flex-col pt-1">
				<TabsList className="relative flex w-full justify-around shadow-collective-page">
					<TabUnderline value={filter} onValueChange={handleFilterChange}>
						<TabsTrigger value="holdings" className={tabStyling}>
							Holdings
						</TabsTrigger>
						<TabsTrigger value="activity" className={tabStyling}>
							Activity
						</TabsTrigger>
					</TabUnderline>
				</TabsList>

				<TabsContent value="holdings" className="h-[65vh] overflow-y-scroll">
					<CollectivesTable collectives={holdings} isHoldings />
				</TabsContent>

				<TabsContent value="activity" className="h-[65vh] overflow-y-scroll">
					<ActivityFeed fanId={fanId} />
				</TabsContent>
			</Tabs>
		</DrawerContent>
	)
}
