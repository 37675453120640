import { useParams } from "@tanstack/react-router"
import { Handle } from "@web/components/modals/ui/Handle"
import { ActivityFeed } from "@web/components/shared/ActivityFeed"
import { Loading } from "@web/components/shared/Loading"
import { OnlineIndicator } from "@web/components/shared/OnlineIndicator"
import { DrawerContent, DrawerTitle } from "@web/components/ui/drawer"
import { useContracts } from "@web/hooks/queries/useContracts"
import { DEFAULT_CONTRACT } from "@web/lib/constants"

export default function ActivityModal() {
	const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)

	if (!contract) {
		return (
			<DrawerContent className="min-h-[99dvh]">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<Handle />
			<DrawerTitle className="my-4 flex items-center">
				<OnlineIndicator backgroundColor="#22c55e" />
				<span className="font-semibold italic">{contract.name}</span>
			</DrawerTitle>

			<div className="h-[80%] w-full overflow-y-scroll">
				<ActivityFeed contractSlug={contractSlug} />
			</div>
		</DrawerContent>
	)
}
