import { useParams } from "@tanstack/react-router"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerContent, DrawerFooter, DrawerHeader } from "@web/components/ui/drawer"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"

const events = {
	deposit: "onboard_modal:deposit_button_click",
	cancel: "onboard_modal:cancel_button_click",
	transfer: "fund_modal:transfer_button_click",
}

export default function OnboardModal() {
	const analytics = useAnalytics(events)
	const { openModal } = useModal()
	const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })

	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { tokenSlug } = contract ?? {}

	const {
		data: [token] = [],
	} = useTokens(tokenSlug)
	const { tokenImageSrc, ticker } = token ?? {}

	function handleClickDeposit() {
		analytics.deposit()
		if (tokenSlug === "usdc") openModal(Modal.Fund)
		else openModal(Modal.Deposit, { tokenSlug })
	}

	function handleClickTransfer() {
		analytics.transfer()
		openModal(Modal.Deposit, { tokenSlug })
	}

	if (!contract || !tokenImageSrc) {
		return (
			<DrawerContent className="min-h-72">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<div className="-mt-8 size-16 overflow-hidden rounded-full border-4 border-white">
				<img src={tokenImageSrc ?? ""} alt={`Symbol for ${ticker}`} className="size-14" />
			</div>

			<DrawerHeader>
				<div className="flex items-center">
					<span className="-mt-[.3rem] mb-[.1rem] font-semibold">Deposit {ticker}</span>
				</div>
			</DrawerHeader>

			<div className="flex flex-col px-8">
				{ticker === "usdc" && (
					<p className="mb-[.9rem] text-center text-gray-primary leading-[1.33rem]">
						This Bracket will trade in <strong>{ticker}</strong>, the digital dollar. Increase your balance using a
						debit card or existing Coinbase account.
					</p>
				)}
				{ticker !== "usdc" && (
					<p className="mb-[.9rem] text-center text-gray-primary leading-[1.33rem]">
						This Bracket will trade in the <strong>{ticker}</strong> token. Increase your account balance from an
						external wallet to get ready.
					</p>
				)}

				<Button
					size="lg"
					onClick={handleClickDeposit}
					className="-mb-1 relative mx-auto mt-[.62rem] w-full bg-blue-primary font-semibold text-lg opacity-90"
				>
					<span className="">I&apos;m ready to fund</span>
					<ChevronRight className="absolute right-2 stroke-white pr-[.2rem]" />
				</Button>
			</div>

			<DrawerFooter className="-mb-1 my-0 flex-row gap-0 text-center text-sm hover:opacity-90 active:opacity-50">
				<Button variant="link" onClick={handleClickTransfer}>
					Or, transfer funds from an external wallet
					<ChevronRight className="size-4 text-blue-primary" />
				</Button>
			</DrawerFooter>
		</DrawerContent>
	)
}
