import { useDynamicContext, useIsLoggedIn } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { useAuthedFan } from "@web/hooks/queries/useFan"
import { usePostHog } from "posthog-js/react"
import { createContext, useEffect } from "react"
import type { Address } from "viem"

interface LoginContext {
	isLoggedIn: boolean
	email?: string
	fanId?: Address
	fanImageSrc?: string | null
	provider?: string
	username?: string
	refetchFan?: () => void
}

export const LoginContext = createContext<LoginContext | null>(null)

interface LoginProviderProps {
	children: React.ReactNode
}

export function LoginProvider(props: LoginProviderProps) {
	const posthog = usePostHog()
	const isLoggedIn = useIsLoggedIn()
	const { showAuthFlow, loadingNetwork } = useDynamicContext()
	console.log("DEBUG: loadingNetwork", loadingNetwork)

	const {
		data: [fan] = [],
		refetch: refetchFan,
	} = useAuthedFan(isLoggedIn)
	const { fanId, email } = fan ?? {}

	// Refetch fan data when user logs in
	useEffect(() => {
		if (isLoggedIn) refetchFan()
	}, [showAuthFlow])

	// Identify user for error tracking
	useEffect(() => {
		if (!isLoggedIn || !fan?.fanId) return
		posthog.identify(fanId, { email })
		Sentry.setUser({ id: fanId, email })
	}, [isLoggedIn, fanId, email])

	const context = { isLoggedIn, refetchFan, ...fan }
	return <LoginContext.Provider value={context}>{props.children}</LoginContext.Provider>
}
