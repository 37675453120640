import { cn, hexToRgba } from "@web/lib/utils"
import type { Collective } from "@web/types"

interface OwnerChipProps {
	collective: Collective
	className?: string
}

export function OwnerChip({ collective, className }: OwnerChipProps) {
	const { fanCount, isActive, primaryColor, fanVotes } = collective
	const formattedFans = (fanCount < 0 ? 0 : fanCount).toLocaleString()
	const isActiveHold = fanVotes > 0 && isActive

	return (
		<div
			className={cn(
				"flex h-full cursor-pointer items-center justify-end py-3 text-center text-gray-primary tracking-wide",
				{ "font-semibold": isActiveHold },
				{ "font-medium": !isActiveHold },
				className,
			)}
			style={{
				color: isActiveHold ? primaryColor : "#717179",
			}}
		>
			<div
				className={cn("mr-1 flex items-center justify-center", {
					"-mr-[.1rem] h-[1.7rem] w-auto rounded-lg border-[1px] px-[.4rem] font-semibold": isActiveHold,
					"opacity-60": fanCount === 0,
				})}
				style={{
					borderColor: hexToRgba(isActiveHold ? primaryColor : "#717179", 0.2),
				}}
			>
				{formattedFans}
			</div>
		</div>
	)
}
