import type { ProviderEnum } from "@dynamic-labs/types"
import { useMutation } from "@tanstack/react-query"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useToast } from "@web/hooks/useToast"
import { client } from "@web/lib/hono"
import type { InferRequestType, InferResponseType } from "hono"

const events = {
	link: "social:profile_link",
	update: "social:profile_update",
	primary: "social:primary_update",
}

export function useSocialMutations() {
	const analytics = useAnalytics(events)
	const { showError } = useToast()

	const $post = client.api.fan.social.$post

	const mutation = useMutation<InferResponseType<typeof $post>, Error, InferRequestType<typeof $post>["json"]>({
		mutationFn: async ({ provider }: { provider: ProviderEnum }) => {
			if (!provider) throw new Error("Provider is required")
			analytics.link({ provider })

			const res = await $post({ json: { provider } })
			if (!res.ok) {
				throw new Error(`Failed to create social profile: ${res.status}`)
			}
			return await res.json()
		},
		onError: (error) => {
			showError("Failed to save new social", error)
		},
	})

	return { connectSocial: mutation.mutateAsync }
}
