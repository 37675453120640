import { CountdownElement } from "@web/components/faqs/CountdownElement"
import { useEffect, useState } from "react"

interface CountdownClockProps {
	startDate: Date | string
	className?: string
}

interface TimeLeft {
	days: number
	hours: number
	minutes: number
	seconds: number
}

export function CountdownClock({ startDate, className }: CountdownClockProps) {
	const calculateTimeLeft = (): TimeLeft | undefined => {
		const difference = +new Date(startDate) - +new Date()
		let timeLeft: TimeLeft | undefined

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			}
		}

		return timeLeft
	}

	const [timeLeft, setTimeLeft] = useState<TimeLeft | undefined>(calculateTimeLeft())

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)

		return () => clearTimeout(timer)
	})

	const addLeadingZero = (value: number): string => {
		return value < 10 ? `0${value}` : value.toString()
	}

	if (!timeLeft) {
		return null
	}

	return (
		<div className={className}>
			<h1 className="mt-5 mb-[.35rem] text-center font-semibold text-[1.25rem]">
				Trading starts in<span>:</span>
			</h1>
			<div className="flex scale-95 items-center justify-center gap-2">
				<CountdownElement number={timeLeft.days.toString()} unit="days" />
				<CountdownElement number={addLeadingZero(timeLeft.hours)} unit="hours" />
				<CountdownElement number={addLeadingZero(timeLeft.minutes)} unit="mins" />
				<CountdownElement number={addLeadingZero(timeLeft.seconds)} unit="secs" />
			</div>
		</div>
	)
}
