import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { useParams } from "@tanstack/react-router"
import { EntryButton } from "@web/components/buttons/EntryButton"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useEntries } from "@web/hooks/queries/useEntries"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { cn } from "@web/lib/utils"
import { useEffect, useState } from "react"

export function ButtonController() {
	const { loadingNetwork } = useDynamicContext()
	const [isVisible, setIsVisible] = useState(false)
	const { contractSlug = DEFAULT_CONTRACT, collectiveSlug } = useParams({ strict: false })

	// Entry button
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { state } = contract ?? {}
	const {
		data: [entries] = [],
	} = useEntries(contract?.contractSlug)
	const { hasEntered } = entries ?? {}

	// Redeem button
	const {
		data: [collective] = [],
	} = useCollectives(contract?.contractSlug, collectiveSlug)
	const { position, fanVotes } = collective ?? {}

	// Conditions
	const isHomePage = !collectiveSlug
	const canEnter = Boolean(isHomePage && !hasEntered && !!state && ["entry", "active"].includes(state))
	const canRedeem = Boolean(
		!isHomePage && fanVotes > 0 && (position ?? 0) > 0 && ["postgame", "finished"].includes(state),
	)

	// Trigger button animations
	useEffect(() => {
		if ((canEnter || canRedeem) && !loadingNetwork) setIsVisible(true)
		else setIsVisible(false)
	}, [canEnter, canRedeem, loadingNetwork])

	if (isHomePage && !contractSlug) return null
	if (!isHomePage && !collectiveSlug) return null

	return (
		<div
			className={cn(
				"w-full max-w-md bg-gradient-to-t from-white via-80% via-white to-transparent px-4 pt-[1.11rem] pb-2.5 transition-all duration-300 ease-in-out",
				{ "translate-y-0": isVisible, "translate-y-full opacity-0": !isVisible },
			)}
		>
			<div className="flex w-full items-center space-x-[.45rem]">
				{canEnter && <EntryButton contract={contract} entries={entries} />}
				{/* {canRedeem && <RedeemButton disabled={!canRedeem} />} */}
			</div>
		</div>
	)
}
