import { PercentChange } from "@web/components/shared/PercentChange"
import { formatPriceString } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import type { Collective } from "@web/types"

interface PriceCellProps {
	collective: Collective
	showAverage?: boolean
}

export function PriceCell({ collective, showAverage = false }: PriceCellProps) {
	const { decimals, state, isActive, averageBuyPrice, collectivePrice, percentChange } = collective
	const formattedPrice = ["pregame", "entry"].includes(state) ? "--" : formatPriceString(collectivePrice, 2, decimals)

	return (
		<div className="mr-[1.1rem] flex h-full cursor-pointer flex-col items-end justify-center py-3 text-right">
			<div className="flex items-center justify-center">
				<span
					className={cn("mt-[.1rem] mb-[.05rem] font-semibold text-[.875rem] tracking-[.01rem]", {
						"text-gray-primary opacity-80": state === "pregame" || !isActive,
					})}
				>
					{formattedPrice}
				</span>
			</div>

			{showAverage && averageBuyPrice && (
				<div>
					<span
						className={cn("text-gray-primary text-xs", {
							"opacity-80": !isActive,
							"opacity-50": averageBuyPrice === "0",
						})}
					>
						{averageBuyPrice === "0" ? "--" : `AVG ${formatPriceString(averageBuyPrice, 2, decimals)}`}
					</span>
				</div>
			)}

			{(!showAverage || !averageBuyPrice) && (
				<PercentChange percent={percentChange} state={state} isActive={isActive} className="text-[0.8125rem]" />
			)}
		</div>
	)
}
