import { Button } from "@web/components/ui/button"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { formatPrice } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import { Modal } from "@web/types"
import { formatEther } from "viem"
import { useBalance } from "wagmi"

interface TokenBalanceProps {
	tokenSlug: string
}

const events = {
	add: "token_balance:add_button_click",
}

export function TokenBalance({ tokenSlug }: TokenBalanceProps) {
	const analytics = useAnalytics(events)
	const { openModal } = useModal()
	const { fanId } = useLoginContext()

	const {
		data: [token] = [],
	} = useTokens(tokenSlug)
	const { tokenImageSrc, ticker, color } = token ?? {}

	const { data: ethBalance, isLoading: isEthLoading } = useBalance({
		address: fanId,
		query: { enabled: !!fanId },
	})
	const { balance: tokenBalance, isLoading: isTokenLoading } = useTokenBalance(tokenSlug)

	function handleClickAdd() {
		analytics.add({ token: tokenSlug })

		if (tokenSlug === "usdc") {
			openModal(Modal.Fund)
		} else {
			openModal(Modal.Deposit, { tokenSlug })
		}
	}

	if (!token) return null

	return (
		<div
			className={cn(
				"mt-2 flex w-full items-center justify-between border-t pt-[.7rem] pb-[.11rem] first:mt-0 first:border-t-0 first:pt-0",
				{ hidden: tokenSlug === "eth" && ethBalance?.value === 0n },
				{ hidden: tokenSlug !== "usdc" && tokenBalance === 0 },
			)}
		>
			<div className="flex items-center">
				<img
					src={tokenImageSrc ?? ""}
					alt={`Symbol for ${ticker}`}
					className="size-[1.75rem] rounded-full"
					style={{ fill: color, color: color }}
				/>

				<span className="ml-[.55rem] h-7 w-px bg-gray-border-vertical" />
				<span
					className={cn("ml-[.65rem] flex font-medium", { "opacity-60": isEthLoading || isTokenLoading })}
					style={{ color: color }}
				>
					{tokenSlug === "eth" && Number(formatEther(ethBalance?.value ?? 0n)).toFixed(2)}
					{tokenSlug !== "eth" && formatPrice(tokenBalance)}
				</span>
				<span className="mt-[.2rem] ml-1 self-start font-normal text-[0.75rem] text-gray-primary">{ticker}</span>
			</div>

			{/* Add Button */}
			<Button
				disabled={tokenSlug !== "usdc"}
				onClick={handleClickAdd}
				className="h-8 px-[.85rem] font-semibold"
				style={{ backgroundColor: color }}
			>
				{tokenSlug === "usdc" ? "Deposit" : "Add"}
			</Button>
		</div>
	)
}
