import { BRACKET_ABI } from "@contract/bracket"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useVotePrice } from "@web/hooks/useVotePrice"
import { checkReceipt } from "@web/lib/trade"
import { publicClient } from "@web/lib/viem"
import { useEffect, useReducer } from "react"
import type { Address } from "viem"
import { useWriteContract } from "wagmi"

type State = {
	status: "paused" | "loading" | "ready" | "pending" | "success" | "error"
	amount: number
	balance: number
	prices: {
		total: bigint
		pool: bigint
		fee: bigint
	}
	hash?: `0x${string}`
	error?: string
}

type Action =
	| { type: "paused" }
	| { type: "updated"; amount?: number; balance?: number; prices?: State["prices"] }
	| { type: "cooldown" }
	| { type: "executed" }
	| { type: "succeeded"; hash: `0x${string}` }
	| { type: "failed"; error?: string }
	| { type: "reset" }

function reducer(state: State, action: Action): State {
	switch (action.type) {
		case "paused":
			return { ...state, status: "paused" }
		case "updated":
			return {
				...state,
				status: "loading",
				amount: action.amount ?? state.amount,
				balance: action.balance ?? state.balance,
				prices: action.prices ?? state.prices,
			}
		case "cooldown":
			return { ...state, status: "ready" }
		case "executed":
			return { ...state, status: "pending" }
		case "succeeded":
			return { ...state, status: "success", hash: action.hash }
		case "failed":
			return { ...state, status: "error", error: action.error }
		case "reset":
			return { ...init() }
		default:
			throw new Error(`Unhandled action type: ${action}`)
	}
}

function init(initialState?: Partial<State>): State {
	return {
		status: "loading",
		amount: initialState?.amount ?? 0,
		balance: initialState?.balance ?? 0,
		prices: {
			total: 0n,
			pool: 0n,
			fee: 0n,
		},
	}
}

function convert(price: bigint) {
	return { total: price, pool: 0n, fee: 0n }
}

function validate(amount: number, balance: number, fan?: Address, collective?: Address) {
	if (!fan) return "Please login to continue"
	if (!collective) return "Please select a collective"
	if (amount <= 0) return "Please enter a valid amount"
	if (amount > balance) return "Insufficient balance"

	return undefined
}

export function useRedeemVotes(contract?: Address, collective?: Address, amount?: number, balance?: number) {
	const { fanId } = useLoginContext()

	const [state, dispatch] = useReducer(reducer, { amount, balance }, init)

	const validationError = validate(state.amount, state.balance, fanId, collective)
	const { redeemPrices } = useVotePrice(contract, collective, state.amount, state.status === "pending") // Lock the price once the user executes the transaction
	const { writeContractAsync, error } = useWriteContract()

	function onInputChange(value: string) {
		// TODO: add debouncing
		const amount = Math.min(Number.parseInt(value, 10) || 0, state.balance)
		dispatch({ type: "updated", amount })
	}

	async function execute() {
		if (!contract || !collective) return
		if (state.status !== "ready") return
		dispatch({ type: "executed" })

		const hash = await writeContractAsync({
			abi: BRACKET_ABI,
			address: contract,
			functionName: "redeemVotes",
			args: [collective, BigInt(state.amount)],
		})

		const error = await checkReceipt(hash, fanId as Address, publicClient)
		if (error) {
			dispatch({ type: "failed", error })
			return { error }
		}

		dispatch({ type: "succeeded", hash })
		return { amount: state.amount }
	}

	// Pause if redeeming is disabled
	// useEffect(() => {
	//   if (isTradingPaused) dispatch({ type: "paused" })
	// }, [isTradingPaused])

	// Validate the transaction before proceeding
	useEffect(() => {
		if (validationError) dispatch({ type: "failed", error: validationError })
	}, [validationError])

	// Failed transaction produces an error
	useEffect(() => {
		if (error) dispatch({ type: "failed", error: error.message })
	}, [error])

	// Price changes automatically trigger updates
	useEffect(() => {
		if (!redeemPrices) return
		dispatch({ type: "updated", prices: convert(redeemPrices) })

		// Provide a brief cooldown before allowing the user to redeem
		const id = setTimeout(() => dispatch({ type: "cooldown" }), 1_000)
		return () => clearTimeout(id)
	}, [redeemPrices?.toString()])

	return {
		state,
		execute,
		reset: () => dispatch({ type: "reset" }),
		isPlusEnabled: state.amount < state.balance,
		isMinusEnabled: state.amount > 1,
		onInputChange,
	}
}
