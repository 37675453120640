import { Link } from "@tanstack/react-router"
import {
	type ColumnDef,
	type RowData,
	type SortingState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { TableEnd } from "@web/components/tables/TableEnd"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@web/components/ui/table"
import { cn } from "@web/lib/utils"
import { Modal } from "@web/types"
import { Fragment, useState } from "react"

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	meta?: Record<string, unknown>
	initialSort?: SortingState
}

export function DataTable<TData extends RowData, TValue>({
	columns,
	data,
	meta,
	initialSort = [],
}: DataTableProps<TData, TValue>) {
	const [sorting, setSorting] = useState<SortingState>(initialSort)
	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		state: {
			sorting,
		},
		defaultColumn: {
			size: 1,
			minSize: 1,
			maxSize: 100,
		},
		meta,
	})

	return (
		<Table className="h-full table-fixed">
			<TableHeader>
				{table.getHeaderGroups().map((headerGroup) => (
					<Fragment key={headerGroup.id}>
						<TableRow className="transition-none">
							{headerGroup.headers.map((header) => {
								return (
									<TableHead key={header.id} className="box-border p-0" style={{ width: `${header.getSize()}%` }}>
										{header.isPlaceholder
											? null
											: flexRender(header.column.columnDef.header, { ...header.getContext() })}
									</TableHead>
								)
							})}
						</TableRow>
					</Fragment>
				))}
			</TableHeader>
			<TableBody>
				{table.getRowModel().rows?.length > 0 ? (
					table.getRowModel().rows.map((row, index) => {
						// biome-ignore lint/suspicious/noExplicitAny: TODO
						const contractSlug = (row.original as any).collective?.contractSlug ?? (row.original as any).contractSlug
						const collectiveSlug =
							// biome-ignore lint/suspicious/noExplicitAny: TODO
							(row.original as any).collective?.collectiveSlug ?? (row.original as any).collectiveSlug
						const hasCollectiveLink = !!contractSlug && !!collectiveSlug && !meta?.disableLinks
						const hasPrizeLink = !!meta?.prizeModalInfo

						return (
							<>
								<TableRow key={row.id} data-state={row.getIsSelected() && "selected"} className="drop-shadow-sm">
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id} className={cn("box-border h-full p-0")}>
											<>
												{/* If contract and collective are set, render a link to the collective */}
												{hasCollectiveLink && (
													<Link
														to="/$contractSlug/$collectiveSlug"
														params={{ contractSlug, collectiveSlug }}
														className="block h-full"
													>
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</Link>
												)}

												{/* If contract and collective are set, render a link to the collective */}
												{hasPrizeLink && (
													<Link to="." search={{ modal: Modal.Prize, rank: index }} className="block h-full">
														{flexRender(cell.column.columnDef.cell, cell.getContext())}
													</Link>
												)}

												{/* If no contract or collective, render the cell as is */}
												{!hasCollectiveLink &&
													!hasPrizeLink &&
													flexRender(cell.column.columnDef.cell, cell.getContext())}
											</>
										</TableCell>
									))}
								</TableRow>
							</>
						)
					})
				) : (
					// If no rows, render a placeholder
					<TableRow>
						<TableCell colSpan={columns.length} className="h-24 text-center text-gray-500">
							No {meta?.isHoldings ? "holdings" : "activity"} yet…
						</TableCell>
					</TableRow>
				)}
				{Boolean(meta?.showTableEnd) && <TableEnd length={columns.length} />}
			</TableBody>
		</Table>
	)
}
