import * as Sentry from "@sentry/react"
import type { Router } from "@tanstack/react-router"

// @ts-ignore TODO: Router accepts generic types
export function initSentry(router: Router) {
	if (import.meta.env.MODE !== "production") return // Do not start in development

	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		tunnel: import.meta.env.VITE_SENTRY_HOST,
		environment: process.env.NODE_ENV,
		integrations: [Sentry.replayIntegration(), Sentry.tanstackRouterBrowserTracingIntegration(router)],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/(.*\.)?bracket\.game\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	})
}
