import { collectiveColumns } from "@web/components/tables/columns/collectives"
import { DataTable } from "@web/components/ui/data-table"
import type { Collective } from "@web/types"

interface CollectivesTableProps {
	collectives: Collective[]
	isTrending?: boolean
	isHoldings?: boolean
	showTableEnd?: boolean
	disableLinks?: boolean
}

export function CollectivesTable({
	collectives,
	isTrending = false,
	isHoldings = false,
	showTableEnd = true,
	disableLinks = false,
}: CollectivesTableProps) {
	const filteredCollectives = isHoldings ? collectives.filter((collective) => collective.fanVotes > 0) : collectives

	return (
		<DataTable
			columns={collectiveColumns}
			data={filteredCollectives}
			meta={{ showTableEnd, disableLinks, isHoldings }}
			initialSort={isTrending ? [{ id: "percent", desc: true }] : []}
		/>
	)
}
