import { cn } from "@web/lib/utils"

interface FlagProps {
	src: string
	name: string
	className?: string
}

export function Flag({ src, name, className }: FlagProps) {
	return (
		<img
			className={cn("gray-border-100 inline h-[0.8rem] w-[0.8rem] rounded-[.18rem]", className)}
			src={src}
			alt={`Flag of ${name.toUpperCase()}`}
		/>
	)
}
