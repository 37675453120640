import { Outlet, createFileRoute } from "@tanstack/react-router"
import { Banner } from "@web/components/contract/Banner"
import { ContractTabs } from "@web/components/contract/ContractTabs"
import { Loading } from "@web/components/shared/Loading"
import { contractsQueryOptions, useContracts } from "@web/hooks/queries/useContracts"

export const Route = createFileRoute("/$contractSlug")({
	component: ContractRoute,
	loader: ({ params, context: { queryClient } }) => {
		const { contractSlug } = params
		queryClient.ensureQueryData(contractsQueryOptions(queryClient, contractSlug))
	},
})

export function ContractRoute() {
	const { contractSlug } = Route.useParams()
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)

	if (!contract) return <Loading />

	return (
		<>
			<ContractTabs className="mt-[.92rem] mb-[.4rem]" />
			<Banner contract={contract} className="mx-[.9rem] mt-[.05rem] mb-[.3rem]" />
			<Outlet />
		</>
	)
}
