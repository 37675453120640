import { TransparentBox } from "@web/components/faqs/TransparentBox"

interface CountdownElementProps {
	number: string // :)
	unit: string
}

export function CountdownElement({ number, unit }: CountdownElementProps) {
	return (
		<TransparentBox className="-skew-x-12 min-w-[4.1rem]" innerClassName="flex-col items-center justify-center">
			<div
				className="absolute top-[37%] left-[0%] w-[100%] border border-white opacity-0"
				style={{
					borderWidth: 0.5,
				}}
			/>
			<span className="mt-1 mb-[.25rem] font-italic font-semibold text-[2.0rem] leading-8">{number}</span>
			<span className="font-medium text-[0.8rem] uppercase opacity-85">{unit}</span>
		</TransparentBox>
	)
}
