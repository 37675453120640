import { Link } from "@tanstack/react-router"
import { cn } from "@web/lib/utils"
import type { Collective } from "@web/types"

interface CollectiveTabsProps {
	collective: Collective
	className?: string
}

function formatNumber(count: number): string {
	if (count < 1000) return count.toString()
	if (count < 1000000) return `${(count / 1000).toFixed(1)}K`
	return `${(count / 1000000).toFixed(1)}M`
}

export function CollectiveTabs({ collective, className }: CollectiveTabsProps) {
	const { contractSlug, collectiveSlug, fanCount } = collective ?? {}
	const tabStyling = "data-[state=active]:font-bold data-[state=active]:text-black data-[state=active]:bg-transparent"

	return (
		<div className={cn("flex grow flex-col", className)}>
			<div className="relative mx-auto flex w-[100%] justify-around py-[.51rem] font-medium text-[.9375rem] text-gray-primary shadow-collective-page transition">
				<Link to="/$contractSlug/$collectiveSlug" params={{ contractSlug, collectiveSlug }} className={tabStyling}>
					<span className="ml-[.1rem] flex w-full items-center justify-center hover:text-black">
						Owners
						{fanCount > 0 && (
							<span className="ml-2 rounded-[.25rem] px-[.3rem] py-[.062rem] text-[0.6875rem] text-gray-primary ring-1 ring-gray-300 hover:font-medium">
								{formatNumber(fanCount)}
							</span>
						)}
					</span>
				</Link>
				<Link
					to="/$contractSlug/$collectiveSlug/activity"
					params={{ contractSlug, collectiveSlug }}
					className={tabStyling}
				>
					<span className="transition hover:text-black">Activity</span>
				</Link>
			</div>
		</div>
	)
}
