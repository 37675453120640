import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@web/components/ui/accordion"
import { ChevronDown } from "lucide-react"
import { useState } from "react" // Import useState for managing accordion state

interface PriceBreakdownProps {
	onClick: () => void
	children: React.ReactNode
}

export function PriceBreakdown(props: PriceBreakdownProps) {
	const [isOpen, setIsOpen] = useState(false)

	const handleToggle = () => {
		setIsOpen(!isOpen)
		props.onClick()
	}

	return (
		<Accordion type="single" collapsible className="mt-4 w-full rounded-[11px] bg-[#F4F4F4] ring-inset">
			<AccordionItem value="breakdown">
				<AccordionTrigger
					onClick={handleToggle}
					className="mx-auto ml-5 justify-center text-center font-medium text-base text-gray-primary hover:text-black hover:no-underline active:text-gray-dark/70"
				>
					<span className={isOpen ? "text-black" : "text-gray-primary"}>Trade Details</span>
					<ChevronDown
						className={`mt-[0rem] ml-[.10rem] inline h-[1.25rem] text-gray-dark/60 ${isOpen ? "rotate-180" : "rotate-0"} transition-transform duration-500`}
						size={20}
					/>
				</AccordionTrigger>
				<AccordionContent className="divide-y divide-gray-border-on-gray/50 px-4">{props.children}</AccordionContent>
			</AccordionItem>
		</Accordion>
	)
}
