import { useNavigate } from "@tanstack/react-router"
import { Label } from "@web/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@web/components/ui/radio-group"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { cn } from "@web/lib/utils"

interface TradeSegmentProps {
	isBuy: boolean
	isActive: boolean
	reset: (isBuy: boolean) => void
	className?: string
}

const events = {
	change: "trade_segment:value_change",
}

export function TradeSegment(props: TradeSegmentProps) {
	const analytics = useAnalytics(events)
	const navigate = useNavigate({ from: "/" })

	function handleChange(value: string) {
		analytics.change({ value })
		navigate({ search: (prev) => ({ ...prev, buy: value === "Buy" }) })
		props.reset(value === "Buy")
	}

	return (
		<RadioGroup
			value={props.isBuy ? "Buy" : "Sell"}
			onValueChange={(value) => handleChange(value)}
			className={cn(
				"-mb-[.0rem] flex h-[2.58rem] w-full items-center justify-between rounded-lg bg-[#F4F4F4]",
				props.className,
			)}
		>
			<div className="ml-[.3rem] flex flex-1 items-center justify-center">
				<RadioGroupItem id="buy" value="Buy" disabled={!props.isActive} className="peer sr-only" />
				<Label
					htmlFor="buy"
					className="mr-[0rem] ml-[0rem] flex h-[2.05rem] w-full cursor-pointer items-center justify-center rounded-[6px] text-center font-semibold text-gray-primary transition peer-data-[state=checked]:bg-blue-primary peer-data-[state=checked]:text-white [&:has([data-state=checked])]:bg-blue-primary [&:has([data-state=checked])]:text-white"
				>
					Buy
				</Label>
			</div>

			<div className="-ml-[.25rem] -mr-1 h-7 w-px bg-gray-border-on-gray" />

			<div className="flex flex-1 items-center justify-center">
				<RadioGroupItem id="sell" value="Sell" className="peer sr-only" />
				<Label
					htmlFor="sell"
					className="mr-[.35rem] ml-[.05rem] flex h-[2.05rem] w-full cursor-pointer items-center justify-center rounded-[6px] text-center font-semibold text-gray-primary transition peer-data-[state=checked]:bg-red-primary peer-data-[state=checked]:text-white [&:has([data-state=checked])]:bg-red-primary [&:has([data-state=checked])]:text-white"
				>
					Sell
				</Label>
			</div>
		</RadioGroup>
	)
}
