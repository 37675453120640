import { Link, useLocation } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import { Avatar } from "@web/components/shared/Avatar"
import { Button } from "@web/components/ui/button"
import { DrawerContent, DrawerFooter } from "@web/components/ui/drawer"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { FARCASTER_URL, TWITTER_URL } from "@web/lib/constants"
import { Modal } from "@web/types"
import { LogOut } from "lucide-react"

const events = {
	faq: "nav_modal:faq_button_click",
	profile: "nav_modal:profile_button_click",
	recover: "nav_modal:recover_button_click",
}

export default function NavModal() {
	const location = useLocation()
	const analytics = useAnalytics(events)
	const { openModal, closeModal } = useModal()
	const { isLoggedIn, login, logout, fanImageSrc, username } = useLoginContext()

	function handleClickMainFAQ() {
		analytics.faq()
		openModal(Modal.FAQs)
	}

	function handleClickProfile() {
		analytics.profile()
		openModal(Modal.Profile)
	}

	function handleLogin() {
		closeModal()
		login()
	}

	function handleLogout() {
		closeModal()
		logout()
	}

	return (
		<DrawerContent className="mb-0 ml-0 flex h-full max-h-screen max-w-[16rem] flex-col rounded-t-none p-[1.2rem] pb-0 sm:max-w-[18rem]">
			<nav className="flex w-full flex-col space-y-6">
				<div className="flex items-center">
					<Logo
						onClick={() => closeModal()}
						className="-mb-1 -mt-[.5rem] size-[2.8rem] cursor-pointer text-blue-brand transition active:scale-[96%]"
					/>
					<span className="mb-6 ml-[.18rem] font-regular text-[.62rem] text-gray-primary/60 uppercase italic">
						Beta
					</span>
				</div>

				<ul className="-mx-3 flex flex-col font-semibold">
					{/* Home (previously Close) */}
					<li className="rounded-[8px] pl-2 transition hover:bg-[#f2f2f2]/70 active:bg-[#efefef]">
						<Link
							to="/"
							onClick={() => closeModal()}
							className={`block px-2 py-3 transition-transform hover:scale-[98%] active:scale-[96%] ${location.pathname === "/" && "text-gray-primary"}`}
						>
							Home
						</Link>
					</li>

					{/* FAQs */}
					<li className="cursor-pointer rounded-[8px] pl-2 transition hover:bg-[#f2f2f2]/70 active:bg-[#efefef]">
						<button
							type="button"
							onClick={handleClickMainFAQ}
							className="block w-full px-2 py-3 text-left transition-transform hover:scale-[98%] active:scale-[96%]"
						>
							Bracket 101
						</button>
					</li>

					{/* Docs */}
					<li className="rounded-[8px] pl-2 transition hover:bg-[#f2f2f2]/70 active:bg-[#efefef]">
						<a
							href="https://github.com/nilli-team/bracket-game-docs/blob/master/Bracket%20Game%20Spec.md"
							target="_blank"
							rel="noopener noreferrer"
							className="block px-2 py-3 transition-transform hover:scale-[98%] active:scale-[96%]"
						>
							View Docs <span className="text-gray-primary/50">↗</span>
						</a>
					</li>

					{/* Sentry Test */}
					{import.meta.env.DEV && (
						<li className="flex items-center rounded-[8px] pl-4 transition hover:bg-[#f2f2f2]/70 active:bg-[#efefef]">
							<button
								type="button"
								onClick={() => {
									throw new Error("Test error for Sentry")
								}}
								className="flex w-full cursor-pointer items-center transition-transform hover:scale-[98%] active:scale-[96%]"
							>
								<span className="block w-full px-1 py-3 text-left ">Test Sentry</span>
							</button>
						</li>
					)}

					{/* Account */}
					{isLoggedIn && (
						<li className="flex items-center rounded-[8px] pl-4 transition hover:bg-[#f2f2f2]/70 active:bg-[#efefef]">
							<button
								type="button"
								onClick={handleClickProfile}
								className="flex w-full cursor-pointer items-center transition-transform hover:scale-[98%] active:scale-[96%]"
							>
								<Avatar src={fanImageSrc} alt={`Avatar of ${username}`} className="mr-2 h-7 w-7" />
								<span className="block w-full px-1 py-3 text-left ">View Account</span>
							</button>
						</li>
					)}
				</ul>
			</nav>

			{/* Login / Logout */}
			<div className="gray-border mt-4 w-full flex-col items-center border-t font-bold italic">
				<Button
					variant={isLoggedIn ? "outline" : "default"}
					onClick={isLoggedIn ? handleLogout : handleLogin}
					size="lg"
					className={`relative mx-auto my-6 flex w-[97%] items-center justify-center overflow-hidden px-9 font-semibold text-[1.0625rem] ${
						!isLoggedIn ? "text-white" : ""
					}`}
				>
					{isLoggedIn ? (
						<>
							<div className="ml-[.5rem] flex items-center">
								<span className="mr-[.25rem]">Log out</span>
								<LogOut className="h-[1.11rem] text-black" />
							</div>
						</>
					) : (
						<>
							<div className="-left-1 -skew-x-12 absolute inset-0 rounded-tr-[.18rem] rounded-br-[.18rem] bg-blue-primary/100 bg-gradient-to-b from-[#7FA4FF]/10 to-blue-brand/50" />
							<span className="relative z-10">Log in or sign up</span>
						</>
					)}
				</Button>
			</div>

			<DrawerFooter className="fixed bottom-[.1rem] left-0 flex w-full flex-col items-center justify-center text-[#797f8b] text-[.8rem]">
				<div className="-mb-[.2rem] flex w-full flex-row justify-evenly">
					<a
						href={TWITTER_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="transition-opacity active:opacity-50"
					>
						@BracketHQ
					</a>
					<a
						href={FARCASTER_URL}
						target="_blank"
						rel="noopener noreferrer"
						className="transition-opacity active:opacity-50"
					>
						Farcaster
					</a>
					<a href="mailto:team@web.game" rel="noopener noreferrer" className="transition-opacity active:opacity-50">
						Contact
					</a>
				</div>
				<div className="w-full text-center text-xs tracking-wide">
					<span>©2024 NILLI, INC. v0.3.</span>
					<span />
				</div>
			</DrawerFooter>
		</DrawerContent>
	)
}
