import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { useParams, useSearch } from "@tanstack/react-router"
import Spinner from "@web/assets/spinner.svg?react"
import { AmountStepper } from "@web/components/modals/ui/AmountStepper"
import { PriceBreakdown } from "@web/components/modals/ui/PriceBreakdown"
import { PriceRow } from "@web/components/modals/ui/PriceRow"
import { TradeSegment } from "@web/components/modals/ui/TradeSegment"
import { VotePrice } from "@web/components/modals/ui/VotePrice"
import { Avatar } from "@web/components/shared/Avatar"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle } from "@web/components/ui/drawer"
import { useEntryMutations } from "@web/hooks/mutations/useEntryMutations"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useEntries } from "@web/hooks/queries/useEntries"
import { useApproval } from "@web/hooks/transactions/useApproval"
import { useTradeVotes } from "@web/hooks/transactions/useTradeVotes"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { SLIPPAGE } from "@web/lib/constants"
import { formatRawPrice } from "@web/lib/formatters"
import { getVotingPower } from "@web/lib/utils"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"
import { useEffect, useState } from "react"
import { formatUnits } from "viem"

const events = {
	approve: "trade_modal:approve_button_click",
	approveError: "trade_modal:approve_tx_error",
	approveSuccess: "trade_modal:approve_tx_success",
	breakdown: "trade_modal:price_breakdown_click",
	cancel: "trade_modal:cancel_button_click",
	trade: "trade_modal:trade_button_click",
	tradeError: "trade_modal:trade_tx_error",
	tradeSuccess: "trade_modal:trade_tx_success",
}
const plural = (amount: number) => `${amount === 1 ? "share" : "shares"}`

// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: needs refactor
export default function TradeModal() {
	const { showWarning } = useToast()
	const analytics = useAnalytics(events)
	const { showSuccess, showError } = useToast()
	const { openModal, closeModal } = useModal()
	const { loadingNetwork } = useDynamicContext()
	const { isLoggedIn, fanId, login } = useLoginContext()

	const { contractSlug, collectiveSlug } = useParams({ from: "/$contractSlug_/$collectiveSlug" })
	const search = useSearch({ from: "__root__" })
	const { buy: initialIsBuy = true } = search
	if (!fanId || !contractSlug || !collectiveSlug) throw new Error("Missing fan id, contract, or collective")

	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { contractId, poolPct, tokenSlug, decimals, tokenId } = contract ?? {}

	const {
		data: [collective] = [],
	} = useCollectives(contractSlug, collectiveSlug)
	const {
		collectiveId,
		fanbase,
		name,
		collectiveImageSrc,
		acronym,
		primaryColor,
		secondaryColor,
		burntVoteCount,
		claimerVoteCount,
		voteCount,
		isActive,
		fanVotes,
	} = collective ?? {}

	const initialAmount = initialIsBuy ? 1 : fanVotes

	const { state, execute, update, reset, isPlusEnabled, isMinusEnabled, onInputChange } = useTradeVotes({
		contract: contractId,
		collective: collectiveId,
		amount: initialAmount,
		balance: fanVotes,
		isBuy: initialIsBuy,
	})
	const { status, prices, amount, isBuy } = state
	const votingPower = getVotingPower(fanVotes, voteCount, burntVoteCount, claimerVoteCount, amount)

	const buyOrSell = isBuy ? "Buy" : "Sell"
	const formattedVotes = `${amount} ${plural(amount)}`
	const formattedVotingPower = Number.parseFloat(votingPower.toString()).toFixed(2)

	const { balance: tokenBalance } = useTokenBalance(tokenSlug)

	// Entry state
	const {
		data: [entries] = [],
	} = useEntries(contract?.contractSlug)
	const [pendingLabel, setPendingLabel] = useState("Entering...")
	const [hasEntered, setHasEntered] = useState(entries?.hasEntered ?? false)
	const [isEntering, setIsEntering] = useState(false)
	const { createEntry } = useEntryMutations()
	const { approve, isApproved, isPending: isApprovalPending, error: approveError } = useApproval(contractId, tokenId)

	async function handleClickEnter() {
		analytics.approve({ contractSlug })

		// Exit early if user has already entered
		if (hasEntered) return

		// Prompt login before entering
		if (!isLoggedIn) {
			login()
			return
		}

		// Approve contract token and create new entry
		setIsEntering(true)
		window.dispatchEvent(new CustomEvent("pendingAirdrop")) // Show spinner in account balance
		try {
			if (!isApproved) {
				setPendingLabel("Approving Contract...")
				await approve()
				analytics.approveSuccess()
			}

			setPendingLabel("Airdropping Points...")
			setTimeout(() => setPendingLabel("Minting Shares..."), 3000)

			const entry = await createEntry({ contractSlug })
			if (!entry) throw new Error("Failed to create entry")

			setHasEntered(true)
			showSuccess("Entered bracket")
		} catch (error) {
			console.error(error)
			showError("Error: Please refresh the page and wait 5 seconds")
			analytics.approveError({ error: approveError })
		} finally {
			setIsEntering(false)
			setPendingLabel("Entering...")
		}
	}

	// biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO: needs refactor
	async function handleClickTrade() {
		analytics.trade()

		// Prompt user to deposit if they don't have enough funds
		const totalPrice = Number(formatUnits(prices.total, decimals))
		if (isBuy && tokenBalance < totalPrice) {
			tokenSlug.startsWith("pts") ? showWarning("You don't have enough points!") : openModal(Modal.Onboard)
			return
		}

		let result: Awaited<ReturnType<typeof execute>> | undefined
		try {
			result = await execute()
			if (result?.amount) {
				const message = `${isBuy ? "Bought" : "Sold"} ${amount} ${amount === 1 ? "voting share" : "voting shares"} of ${name}`
				showSuccess(message)
				analytics.tradeSuccess({
					result,
					message,
					contractSlug,
					collectiveSlug,
				})
			} else throw new Error()
		} catch (error) {
			// @ts-ignore
			const message = `Transaction error: ${result?.error ?? error?.shortMessage ?? "Please refresh the page"}`
			// const message = "Tx rejected due to price increase."
			showError("Error: Please refresh the page and wait 5 seconds")
			analytics.tradeError({ error, message })
		} finally {
			closeModal()
		}
	}

	// Reset when the modal closes
	// NOTE: strict mode causes the initial amount to be forcibly set to 1
	useEffect(() => {
		return () => {
			reset(initialIsBuy)
		}
	}, [])

	if (!collectiveSlug) {
		return (
			<DrawerContent className="min-h-72">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<Avatar
				alt={`Collective ${fanbase}`}
				acronym={acronym}
				src={collectiveImageSrc}
				primaryColor={primaryColor}
				secondaryColor={secondaryColor}
				className="-mt-8 size-16 border-4 border-white "
			/>
			<DrawerHeader className="p-3">
				<DrawerTitle className="-mb-[.14rem] -mt-2 relative font-normal text-sm tracking-[.01rem]">
					<div className="max-w-[16rem]">
						<span className="font-semibold italic">{`${buyOrSell} ${fanbase}`}</span>
					</div>
				</DrawerTitle>
			</DrawerHeader>

			<div className="flex w-full flex-col items-center justify-center px-5">
				<VotePrice
					prices={prices}
					amount={amount}
					isBuy={isBuy}
					isLoading={status === "loading"}
					type="trade"
					tokenSlug={tokenSlug}
				/>

				<TradeSegment isBuy={isBuy} isActive={Boolean(isActive)} reset={update} className="mt-4" />

				<AmountStepper
					label="Voting Shares"
					amount={amount}
					color={primaryColor}
					max={isBuy ? undefined : state.balance}
					isBuy={isBuy}
					isPlusEnabled={isPlusEnabled}
					isMinusEnabled={isMinusEnabled}
					onInputChange={onInputChange}
					className="mt-4"
				/>

				<PriceBreakdown onClick={() => analytics.breakdown()}>
					<PriceRow
						label={`${amount}x Voting ${plural(amount)} (${isBuy ? "+" : "-"}${formattedVotingPower}%)`}
						value={`${formatRawPrice(prices.base, decimals)}`}
					/>
					<PriceRow label={`${poolPct / 100}% Transaction fee`} value={`${formatRawPrice(prices.pool, decimals)}`} />
					{isBuy && (
						<PriceRow
							label={`${SLIPPAGE}% Slippage protection`}
							value={`${formatRawPrice(prices.slippage, decimals)} Max`}
						/>
					)}
				</PriceBreakdown>
			</div>

			<DrawerFooter className="-mt-1 w-full gap-0 px-5">
				{/* Pending approval */}
				{(!hasEntered || !isApproved) && (isEntering || isApprovalPending) && (
					<Button
						size="lg"
						variant={isBuy ? "default" : "destructive"}
						onClick={handleClickEnter}
						className="relative font-semibold text-[1.0625rem]"
						disabled
					>
						{pendingLabel}
						<Spinner className="absolute right-[.85rem] size-5 animate-spin-fast text-white" />
					</Button>
				)}

				{/* Ready for approval */}
				{(!hasEntered || !isApproved) && !isEntering && !isApprovalPending && (
					<Button
						size="lg"
						variant={isBuy ? "default" : "destructive"}
						onClick={handleClickEnter}
						disabled={loadingNetwork}
						className="relative font-semibold text-[1.0625rem]"
					>
						Enter Bracket
						<ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
					</Button>
				)}

				{/* Pending trade */}
				{hasEntered && isApproved && status === "pending" && (
					<Button
						size="lg"
						variant={buyOrSell === "Buy" ? "default" : "destructive"}
						className="relative h-14 font-semibold text-[1.0625rem] "
						disabled
					>
						{buyOrSell}ing {formattedVotes}
						<Spinner className="absolute right-4 h-6 w-6 animate-spin-fast text-white" />
					</Button>
				)}

				{/* Ready for trade */}
				{hasEntered && isApproved && status !== "pending" && (
					<Button
						size="lg"
						variant={isBuy ? "default" : "destructive"}
						onClick={handleClickTrade}
						className="relative h-14 font-semibold text-[1.0625rem] "
						disabled={status !== "ready" || (isBuy && !isActive) || loadingNetwork}
					>
						{buyOrSell} {!isBuy && amount === fanVotes && fanVotes > 1 ? `all ${amount} shares` : formattedVotes}
						<ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
					</Button>
				)}

				<DrawerClose
					onClick={() => analytics.cancel()}
					className="mt-[.75rem] text-[.9375rem] text-gray-dark hover:opacity-90 active:opacity-50"
				>
					Close
				</DrawerClose>
			</DrawerFooter>
		</DrawerContent>
	)
}
