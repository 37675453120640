import { Link, useParams } from "@tanstack/react-router"
import { ButtonController } from "@web/components/buttons/ButtonController"
import { TabBar } from "@web/components/layout/TabBar"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { cn, isIOS } from "@web/lib/utils"
import { Modal } from "@web/types"
import { Info } from "lucide-react"
import { useEffect, useState } from "react"

export function Footer() {
	const [isVisible, setIsVisible] = useState(true)
	const { contractSlug = DEFAULT_CONTRACT, collectiveSlug } = useParams({ strict: false })
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const {
		data: [collective] = [],
	} = useCollectives(contractSlug, collectiveSlug)

	const { prizeModalInfo } = contract ?? {}
	const isPrizeBannerShown = !!collectiveSlug && prizeModalInfo?.info && collective?.isActive

	// Hide the tab bar when the user scrolls down
	useEffect(() => {
		let lastScrollTop = 0
		let scrollDelta = 0
		const scrollThreshold = 24

		const handleScroll = () => {
			const currentScroll = window.scrollY
			scrollDelta += currentScroll - lastScrollTop

			if (Math.abs(scrollDelta) >= scrollThreshold) {
				if (scrollDelta > 0) {
					setIsVisible(false)
				} else {
					setIsVisible(true)
				}
				scrollDelta = 0
			}

			lastScrollTop = currentScroll <= 0 ? 0 : currentScroll
		}

		window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
	}, [])

	return (
		<footer
			className={cn(
				"fixed right-0 bottom-0 left-0 z-20 flex flex-col items-center transition-all duration-300 ease-in-out",
				{ "translate-y-0": isVisible, "translate-y-[3.75rem]": !isVisible && !isIOS() },
			)}
		>
			<ButtonController />
			{isPrizeBannerShown && (
				<Link
					to="."
					search={{ modal: Modal.Prize, rank: 0 }}
					className="flex h-12 w-full items-center justify-center bg-white/40 px-4 text-gray-primary text-sm backdrop-blur-md"
				>
					<div
						className="flex items-center gap-1 transition-all active:opacity-50"
						// biome-ignore lint/security/noDangerouslySetInnerHtml: set dynmaically from db
						dangerouslySetInnerHTML={{ __html: prizeModalInfo.info }}
					/>
					<Info className="mb-[.1rem] ml-[.31rem] inline size-[.9rem] translate-y-[.1rem] text-gray-primary/80" />
				</Link>
			)}
			<TabBar />
		</footer>
	)
}
