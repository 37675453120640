import Spinner from "@web/assets/spinner.svg?react"
import { cn } from "@web/lib/utils"

interface LoadingProps {
	className?: string
}

export function Loading({ className }: LoadingProps) {
	return (
		<div
			className={cn("pointer-events-none absolute inset-0 flex items-center justify-center overflow-hidden", className)}
		>
			<Spinner className="size-6 animate-spin-fast text-blue-primary" />
		</div>
	)
}
