import type { ColumnDef } from "@tanstack/react-table"
import { Avatar } from "@web/components/shared/Avatar"
import { Chip } from "@web/components/shared/Chip"
import { Username } from "@web/components/shared/Username"
import { ColumnHeader } from "@web/components/tables/ColumnHeader"
import { PrizeRank } from "@web/components/tables/PrizeRank"
import { formatVotingPower, getVotingPower } from "@web/lib/utils"
import type { Owner } from "@web/types"
import type { Address } from "viem"

export const ownerColumns: ColumnDef<Owner>[] = [
	{
		id: "rank",
		accessorFn: (_, index) => index + 1,
		size: 12,
		header: ({ column }) => (
			<ColumnHeader column={column} className="ml-3 w-fit">
				#
			</ColumnHeader>
		),
		cell: (cell) => {
			const rank: number = cell.row.getValue("rank")
			const { isActive } = cell.row.original
			// @ts-ignore
			return <PrizeRank rank={rank} isActive={isActive} prizeModalInfo={cell.table.options.meta.prizeModalInfo} />
		},
	},
	{
		accessorKey: "username",
		size: 48,
		header: ({ column }) => (
			<ColumnHeader column={column} className="ml-9 w-fit">
				Owner
			</ColumnHeader>
		),
		cell: ({ row }) => {
			const { fanId, fanImageSrc, provider, username } = row.original
			return (
				<div className="ml-1 flex items-center overflow-hidden py-3">
					<Avatar src={fanImageSrc} alt={`Avatar of ${username}`} fanId={fanId as Address} className="size-8" />
					<Username fanId={fanId as Address} username={username} provider={provider} className="ml-2 font-semibold" />
				</div>
			)
		},
	},
	{
		accessorKey: "fanVotes",
		minSize: 26,
		header: ({ column }) => (
			<ColumnHeader column={column} position="right" className="mr-[2rem] w-fit">
				<div className="w-full text-right">HOLDING</div>
			</ColumnHeader>
		),
		cell: ({ row }) => {
			const { fanVotes, primaryColor } = row.original
			return (
				<div className="float-right mr-11 flex h-full cursor-pointer items-center justify-end text-right">
					<Chip color={primaryColor} className="my-3 inline-block">
						{fanVotes}
					</Chip>
				</div>
			)
		},
	},
	{
		id: "percentage",
		accessorFn: (row) => {
			const { fanVotes, voteCount, burntVoteCount, claimerVoteCount } = row
			return getVotingPower(fanVotes, voteCount, burntVoteCount, claimerVoteCount)
		},
		size: 15,
		header: ({ column }) => (
			<ColumnHeader column={column} position="right" className="mr-[.75rem] w-fit">
				VOTE<span className="ml-[0.1rem]">%</span>
			</ColumnHeader>
		),
		cell: ({ row }) => {
			const percentage: number = row.getValue("percentage")
			return (
				<div className="mr-5 flex h-full cursor-pointer items-center justify-end text-right font-mono text-[.875rem] ">
					{formatVotingPower(percentage)}%
				</div>
			)
		},
	},
]
