import { Chip } from "@web/components/shared/Chip"
import { cn, formatVotingPower, getVotingPower } from "@web/lib/utils"
import type { Collective, Contract, Entry } from "@web/types"

interface CollectivePrizeProps {
	contract: Contract
	collective: Collective
	entries: Entry
	className?: string
}

export function CollectivePrize({ collective, className }: CollectivePrizeProps) {
	const { primaryColor, secondaryColor, voteCount, burntVoteCount, claimerVoteCount, fanVotes } = collective ?? {}

	const votingPower = getVotingPower(fanVotes, voteCount, burntVoteCount, claimerVoteCount)
	const isHeldByUser = fanVotes > 0

	const Divider = () => <span className="-skew-x-12 mr-[.55rem] ml-[0.55rem] h-[1.45rem] w-[1px] bg-[#D8DADF]" />

	if (!isHeldByUser) return null

	return (
		<div className={cn("flex items-center justify-start", className)}>
			{/* Share balance & voting power */}
			<Chip
				color={`${primaryColor}E6`} // Set opacity to 90% using hex code
				textColor={`${secondaryColor}`}
				className="mx-0 scale-[88%] pr-[.39rem] ring-[1px] ring-black/10 ring-inset"
				fullOpacity
			>
				{fanVotes}
			</Chip>
			<Divider />
			<span className="font-mono text-sm uppercase">
				{formatVotingPower(Number.isNaN(votingPower) ? 0 : votingPower)}%
			</span>
		</div>
	)
}
