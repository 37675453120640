import { formatUnits } from "viem"

const isInvalidNumber = (value: string | number | bigint) =>
	(typeof value === "number" && Number.isNaN(value)) || !value || value === "NaN"

function bigintToNumber(value: bigint | number | string, tokenDecimals: number) {
	const bn = typeof value === "bigint" ? value : BigInt(value)
	return Number.parseFloat(formatUnits(bn, tokenDecimals))
}

function scientificToBigInt(value: string): bigint {
	const [mantissa, exponent] = value.toLowerCase().split("e")
	if (!exponent) {
		return BigInt(value)
	}
	const decimals = mantissa.split(".")[1]?.length || 0
	const normalizedMantissa = mantissa.replace(".", "")
	const exp = BigInt(exponent) - BigInt(decimals)
	return BigInt(normalizedMantissa) * (exp >= 0 ? 10n ** BigInt(exp) : 1n / 10n ** BigInt(-exp))
}

export function formatPrice(value: string | number, decimals = 2): string {
	if (isInvalidNumber(value)) return "0.0"

	return Number(value).toLocaleString("en-US", {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
}

export function formatRawPrice(value: number | bigint | string, tokenDecimals: number, decimals = 2) {
	if (isInvalidNumber(value)) return "0.0"

	return bigintToNumber(value, tokenDecimals).toLocaleString("en-US", {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
}

export function formatPriceString(value: string, decimals = 2, tokenDecimals = 18) {
	try {
		// Handle scientific notation without using Number
		const bigIntValue = scientificToBigInt(value)

		return Number(formatUnits(bigIntValue, tokenDecimals)).toLocaleString("en-US", {
			minimumFractionDigits: decimals,
			maximumFractionDigits: decimals,
		})
	} catch (e) {
		return "0.0"
	}
}
