import { useParams, useSearch } from "@tanstack/react-router"
import { Handle } from "@web/components/modals/ui/Handle"
import { Loading } from "@web/components/shared/Loading"
import {
	Carousel,
	type CarouselApi,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from "@web/components/ui/carousel"
import { DrawerContent } from "@web/components/ui/drawer"
import { useContracts } from "@web/hooks/queries/useContracts"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { useEffect, useState } from "react"

export default function PrizeModal() {
	const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
	const search = useSearch({ from: "__root__" })
	const { rank = 1 } = search

	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { prizeModalInfo } = contract ?? {}

	// Carousel state
	const [api, setApi] = useState<CarouselApi>()
	const [current, setCurrent] = useState<number>(rank - 1)

	useEffect(() => {
		if (!api) return
		api.scrollTo(current)
		api.on("select", () => setCurrent(api.selectedScrollSnap()))
	}, [api])

	// Update current when prizeModalInfo becomes available
	useEffect(() => {
		if (!prizeModalInfo) return

		const activeSlide = prizeModalInfo.slides.find((info) => rank >= info.startIndex && rank <= info.endIndex)
		if (activeSlide) {
			setCurrent(prizeModalInfo.slides.indexOf(activeSlide))
		}
	}, [prizeModalInfo, rank])

	if (!prizeModalInfo) {
		return (
			<DrawerContent className="min-h-72">
				<Loading />
			</DrawerContent>
		)
	}

	return (
		<DrawerContent>
			<Handle />

			<div className="flex flex-col items-center justify-center px-5">
				<Carousel setApi={setApi} className="">
					<CarouselContent className="h-full">
						{prizeModalInfo.slides.map((slide, index) => (
							<CarouselItem
								// biome-ignore lint/suspicious/noArrayIndexKey: TODO: better index key for slides
								key={index}
								className="flex flex-col items-center justify-center bg-white bg-opacity-[.89] pb-3 text-center backdrop-blur-sm"
							>
								<h3 className="tight mt-2 font-semibold text-xl">{slide.header}</h3>
								<p className="mt-1 mb-2 text-gray-primary text-sm">{slide.subheader}</p>
								<a href={slide.url} className="floating-item mx-auto mt-4 h-[21rem] max-w-[20rem] mix-blend-multiply">
									<img
										src={slide.imageSrc}
										alt={`Prize for ${slide.header}`}
										className="rotate-[3deg] rounded-3xl bg-gray-100 shadow transition hover:rotate-[0deg] hover:scale-[101%] active:scale-[98%]"
									/>
								</a>
								<p className="mb-4 font-semibold text-blue-primary text-lg">{slide.content}</p>
							</CarouselItem>
						))}
					</CarouselContent>
					{prizeModalInfo.slides.length > 1 && (
						<CarouselPrevious className="absolute top-1/2 left-3.5 hover:bg-gray-300/70" />
					)}
					{prizeModalInfo.slides.length > 1 && (
						<CarouselNext className="absolute top-1/2 right-3.5 hover:bg-gray-300/70" />
					)}
				</Carousel>
			</div>
		</DrawerContent>
	)
}
