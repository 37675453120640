import {
	PaginationContent,
	PaginationFirst,
	PaginationItem,
	PaginationLast,
	PaginationLink,
	PaginationRoot,
} from "@web/components/ui/pagination"
import { useAnalytics } from "@web/hooks/useAnalytics"

const events = {
	first: "pagination:first_button_click",
	last: "pagination:last_button_click",
	page: "pagination:page_button_click",
}

interface PaginationProps {
	page: number
	setPage: (page: number) => void
	total: number
}

export function Pagination(props: PaginationProps) {
	const analytics = useAnalytics(events)
	const { page, setPage, total } = props
	const maxPagesToShow = 3
	const pagesWindow = getPagesToRender(total, page, maxPagesToShow)

	const showNext = page !== total
	const showPrev = page !== 1

	function handleClickFirst() {
		analytics.first()
		if (page > 1) {
			setPage(1)
		}
	}

	function handleClickLast() {
		analytics.last()
		if (page < total) {
			setPage(total)
		}
	}

	function handleClickPage(page: number) {
		analytics.page({ page })
		setPage(page)
	}

	if (total <= 1) return null

	return (
		<PaginationRoot className="mt-4 w-full px-3 text-[#878787]">
			<PaginationContent className="flex w-full justify-between">
				<PaginationItem className={showPrev ? "" : "pointer-events-none opacity-0"}>
					<PaginationFirst onClick={handleClickFirst} />
				</PaginationItem>

				<div className="flex justify-between">
					{pagesWindow.map((p) => (
						<PaginationItem className="mx-[0.4rem]" key={p}>
							<PaginationLink isActive={page === p} onClick={() => handleClickPage(p)}>
								{p}
							</PaginationLink>
						</PaginationItem>
					))}
				</div>

				<PaginationItem className={showNext ? "" : "pointer-events-none opacity-0"}>
					<PaginationLast onClick={handleClickLast} />
				</PaginationItem>
			</PaginationContent>
		</PaginationRoot>
	)
}

/**
 * @description
 * Gets a window of pages that should be rendered: selected + a few from both sides.
 * Always returns the amount set in {@link amountToRender} param, or total amount if it's lower
 *
 * @param total - amount of pages
 * @param currentPage
 * @param amountToRender - the amount of pages that will be returned. Should be a odd number
 */
function getPagesToRender(total: number, currentPage: number, amountToRender: number): number[] {
	// Generate array of pages from total number
	const pages = Array.from({ length: total }).map((_, index) => index + 1)

	const finalAmountToRender = Math.min(total, amountToRender)

	// Find the index of the selected number
	const selectedIndex = pages.indexOf(currentPage)

	// Calculate the left and right boundaries of the window
	let leftBoundary = Math.max(0, selectedIndex - Math.floor(finalAmountToRender / 2))
	let rightBoundary = Math.min(pages.length, selectedIndex + Math.floor(finalAmountToRender / 2) + 1)

	// Adjust boundaries if the window is not fully filled
	if (rightBoundary - leftBoundary < finalAmountToRender) {
		if (leftBoundary === 0) {
			rightBoundary = finalAmountToRender
		} else {
			leftBoundary = Math.max(0, rightBoundary - finalAmountToRender)
		}
	}

	// Return the subarray
	return pages.slice(leftBoundary, rightBoundary)
}
