import { useDynamicContext, usePasskeyRecovery } from "@dynamic-labs/sdk-react-core"
import Checkmark from "@web/assets/checkmark.svg?react"
import { TokenBalance } from "@web/components/modals/ui/TokenBalance"
import { Avatar } from "@web/components/shared/Avatar"
import { Loading } from "@web/components/shared/Loading"
import { Button } from "@web/components/ui/button"
import { DrawerClose, DrawerContent, DrawerFooter } from "@web/components/ui/drawer"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useToast } from "@web/hooks/useToast"
import { cn, shorten } from "@web/lib/utils"
import { Modal } from "@web/types"
import { ChevronDown, ChevronRight, Copy, KeyRound, LogOut, Plus, UserRoundPen } from "lucide-react"
import { useEffect } from "react"

const events = {
	copy: "profile_modal:copy_button_click",
	activity: "profile_modal:activity_button_click",
	withdraw: "profile_modal:withdraw_button_click",
	logout: "profile_modal:logout_button_click",
	export: "profile_modal:export_button_click",
	wallet: "profile_modal:wallet_button_click",
	passkey: "profile_modal:passkey_button_click",
	social: "profile_modal:social_button_click",
}

export default function ProfileModal() {
	const analytics = useAnalytics(events)
	const { openModal, closeModal } = useModal()
	const { showError, showSuccess } = useToast()
	const { setShowDynamicUserProfile } = useDynamicContext()

	const { logout, fanImageSrc, username, fanId, provider, isLoggedIn } = useLoginContext()
	const { showAuthFlow, setShowAuthFlow } = useDynamicContext()
	const { initPasskeyRecoveryProcess } = usePasskeyRecovery()

	function handleClickPlus() {
		analytics.social()
		if (provider === "bracket") openModal(Modal.Social)
		else setShowDynamicUserProfile(true)
	}

	function handleClickSocial() {
		analytics.social()
		openModal(Modal.Social)
	}

	function handleClickCopy() {
		analytics.copy()
		if (navigator.clipboard) {
			navigator.clipboard.writeText(fanId as `0x${string}`)
			showSuccess("Your address was copied to clipboard!")
		} else {
			showError("Something went wrong")
		}
	}

	function handleClickActivity() {
		analytics.activity()
		openModal(Modal.Fan, { fanId, filter: "activity" })
	}

	function handleClickWithdraw() {
		analytics.withdraw()
		openModal(Modal.Offboard)
	}

	function handleClickLogout() {
		analytics.logout()
		closeModal()
		logout()
	}

	function handleClickPasskey() {
		analytics.passkey()
		initPasskeyRecoveryProcess()
	}

	// Prompt signup if the user is not logged in
	useEffect(() => {
		if (showAuthFlow) closeModal()
		if (!isLoggedIn) setShowAuthFlow(true)
		// if (provider === "bracket") openModal(Modal.Social)
	}, [showAuthFlow])

	// TODO: We should also block on the fan's socials
	if (!fanId || !username) {
		return (
			<div className="min-h-72">
				<Loading />
			</div>
		)
	}

	return (
		<DrawerContent className="px-6">
			<div className="relative">
				<Avatar
					src={fanImageSrc}
					alt={`Avatar of ${username}`}
					className="-mt-10 size-20 border-4 border-white bg-black"
				/>
				<button
					type="button"
					className={cn(
						"absolute right-0 bottom-0 flex size-6 items-center justify-center rounded-full border-2 border-white bg-blue-primary",
						{ "border-none bg-transparent": provider !== "bracket" },
					)}
					onClick={handleClickPlus}
				>
					<Plus className="size-3 stroke-[3] stroke-white" />
				</button>
			</div>

			<div className="relative mt-2 flex w-full items-center justify-center">
				{/* Username Pill Button */}
				<Button
					variant="outline"
					className="rounded-full bg-white text-[.9375rem] opacity-[1]"
					onClick={handleClickSocial}
				>
					<span className="font-medium">@</span>
					<span className="font-semibold">{username}</span>
					{provider === "farcaster" && (
						<Checkmark className="mt-[.025rem] ml-[.2rem] size-[.88rem] text-purple-farcaster" />
					)}
					{provider === "twitter" && <Checkmark className="mt-[.025rem] ml-[.2rem] size-[.88rem] text-black" />}
					<ChevronDown className="-mr-[.3rem] mt-[.13rem] ml-[.1rem] size-4 text-[#84858E]" />
				</Button>

				<Button
					variant="outline"
					size="icon"
					onClick={handleClickSocial}
					className="absolute right-0 rounded-xl bg-none"
				>
					<UserRoundPen className="size-[1.2rem]" />
				</Button>
			</div>

			<Button variant="link" onClick={handleClickActivity}>
				View your profile
				<ChevronRight className="mt-[1px] size-[.92rem] text-blue-primary" />
			</Button>

			<div className="flex w-full flex-col">
				{/* Top row */}
				<div className="-mb-[.05rem] flex w-full items-center justify-between px-4">
					<span className="-ml-1 font-medium text-sm">Available Balance</span>

					<div className="flex items-center">
						{/* Copy wallet address */}
						<Button variant="disappear" onClick={handleClickCopy} className="p-0 font-medium text-gray-link">
							<span className="relative mr-[.4rem] inline-block h-[.35rem] w-[.35rem] animate-pulse rounded-full bg-green-500" />
							{shorten(fanId as `0x${string}`)}
							<Copy className="ml-[.4rem] size-4 text-gray-primary/50" />
						</Button>
					</div>
				</div>

				{/* Token balances */}
				<div className="flex w-full flex-col items-center rounded-[.9rem] border border-gray-light px-4 py-3 font-medium">
					<TokenBalance tokenSlug="usdc" />
					<TokenBalance tokenSlug="degen" />
					<TokenBalance tokenSlug="higher" />
					<TokenBalance tokenSlug="eth" />
				</div>

				{/* Bottom row */}
				<div className="flex items-center justify-between">
					<Button variant="link" onClick={handleClickPasskey} className="-ml-1 text-gray-link">
						<KeyRound className="size-4" />
						<span className="ml-1">Recover Passkey</span>
					</Button>

					<Button variant="link" onClick={handleClickWithdraw} className="text-blue-primary">
						Withdraw
						<ChevronRight className="-mr-2 size-[.92rem] text-blue-primary" />
					</Button>
				</div>
			</div>

			<DrawerFooter className="-mt-1 mb-2 font-semibold text-base">
				<DrawerClose onClick={handleClickLogout}>
					<span className="text-black">Log out</span>
					<LogOut className="ml-1 inline h-[1.15rem] text-black" />
				</DrawerClose>
			</DrawerFooter>
		</DrawerContent>
	)
}
