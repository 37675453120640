import { Link, useParams } from "@tanstack/react-router"
import { DEFAULT_CONTRACT } from "@web/lib/constants"
import { cn, isIOS } from "@web/lib/utils"
import { BarChart2, TicketCheck, TrendingUp, User } from "lucide-react"

interface Tab {
	icon: React.ReactNode
	label: string
	to?: string
	search?: Record<string, unknown>
}

const TABS: Tab[] = [
	{
		icon: <TrendingUp className="mb-[.15rem] size-[1.52rem] translate-y-[.37rem] stroke-[1.35px]" />,
		label: "Bracket",
		to: "/$contractSlug",
		search: { modal: undefined },
	},
	{
		icon: <BarChart2 className="size-[1.7rem] translate-y-[.16rem] stroke-[1.5px]" />,
		label: "My Positions",
		to: "/$contractSlug/holdings",
		search: undefined,
	},
	{
		icon: <TicketCheck className="size-[1.7rem] translate-y-[.21rem] stroke-[1.1px]" />,
		label: "Rules",
		to: undefined,
		search: { modal: "faqs" },
	},
	{
		icon: <User className="mb-[.05rem] size-[1.6rem] translate-y-[.17rem] stroke-[1.45px]" />,
		label: "Account",
		to: undefined,
		search: { modal: "profile" },
	},
]

export function TabBar() {
	const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })

	return (
		<div
			className={cn(
				"flex w-full max-w-md items-center justify-around border-[#f1f3fa] border-t bg-white px-[.5rem] pt-[.3rem]",
				{ "pb-[.64rem]": !isIOS() },
			)}
		>
			{TABS.map((tab, index) => (
				// biome-ignore lint/suspicious/noArrayIndexKey: TODO: better index key for tabs
				<TabBar.Item key={index} contractSlug={contractSlug} {...tab} />
			))}
		</div>
	)
}

TabBar.Item = ({ icon, label, to, search, contractSlug }: Tab & { contractSlug: string }) => {
	return (
		<Link
			to={to}
			params={{ contractSlug }}
			search={{ ...search }}
			activeOptions={{ exact: true }}
			activeProps={{ className: "text-blue-brand" }}
			inactiveProps={{ className: "text-gray-500" }}
			className="-mt-[.1rem] flex cursor-pointer flex-col items-center"
		>
			{icon}
			<span className="mt-[.15rem] text-[.7rem]">{label}</span>
		</Link>
	)
}
