import { useNavigate, useSearch } from "@tanstack/react-router"
import type { Filter, Modal } from "@web/types"
import type { Address } from "viem"

interface ModalState {
	buy?: boolean
	fanId?: Address
	filter?: Filter
	modal?: Modal
	page?: number
	price?: number
	quantity?: number
	tokenSlug?: string
}

export function useModal() {
	const search = useSearch({ from: "__root__" })
	const navigate = useNavigate({ from: "/" })
	const { modal: activeModal } = search

	function openModal(modal: Modal, state?: ModalState) {
		navigate({ search: (prev) => ({ ...prev, ...state, modal }) })
	}

	function closeModal(open = false) {
		if (open) return
		// @ts-ignore NOTE: only filter is preserved on close
		navigate({ search: ({ filter, ...prev }) => ({ filter }) })
	}

	return { activeModal, openModal, closeModal }
}
