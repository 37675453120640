import { cn } from "@web/lib/utils"

interface ChipProps {
	color: string
	children: React.ReactNode
	fullOpacity?: boolean
	textColor?: string
	className?: string
}

export function Chip({ color, className, children, fullOpacity = false, textColor = color }: ChipProps) {
	const backgroundColor = fullOpacity ? color : `${color}1A`
	const gradientStart = `${color}02` // 2% opacity
	const gradientEnd = `${color}40` // 25% opacity

	return (
		<div
			className={cn("-skew-x-12 relative mx-auto w-fit rounded-[.18rem] px-[.35rem] py-[.03rem]", className)}
			style={{ backgroundColor }}
		>
			<span className="relative skew-x-12 font-bold" style={{ color: textColor }}>
				{children}
			</span>
			<div
				className="-z-10 absolute top-0 left-0 h-full w-full rounded-[.18rem] opacity-40"
				style={{
					border: "1px solid transparent",
					background: `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd}) border-box`,
					WebkitMask: "linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)",
					WebkitMaskComposite: "destination-out",
					maskComposite: "exclude",
				}}
			/>
		</div>
	)
}
