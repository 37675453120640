import { BRACKET_ABI } from "@contract/bracket"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import type { Address } from "viem"
import { useReadContracts } from "wagmi"

export function useContractState(address?: Address) {
	const { data } = useReadContracts({
		contracts: [{ abi: BRACKET_ABI, address, functionName: "txPaused" }],
		query: {
			enabled: !!address,
			refetchInterval: DEFAULT_POLLING_INTERVAL,
		},
	})
	const isTradingPaused = data?.[0].result as boolean

	return { isTradingPaused }
}
