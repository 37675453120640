import { cn } from "@web/lib/utils"
import * as React from "react"
import { useEffect, useState } from "react"
import { Drawer as DrawerPrimitive } from "vaul"

const Drawer = ({
	shouldScaleBackground = true,
	children,
	...props
}: React.ComponentProps<typeof DrawerPrimitive.Root>) => {
	const [open, setOpen] = useState(false)
	const [renderChildren, setRenderChildren] = useState(false)

	useEffect(() => {
		let id: ReturnType<typeof setTimeout>
		if (props.open) {
			setRenderChildren(true)
			id = setTimeout(() => setOpen(true), 100)
		} else {
			setOpen(false)
			id = setTimeout(() => setRenderChildren(false), 300)
		}

		return () => clearTimeout(id)
	}, [props.open])

	return (
		<DrawerPrimitive.Root shouldScaleBackground={shouldScaleBackground} open={open} {...props}>
			{renderChildren ? children : undefined}
		</DrawerPrimitive.Root>
	)
}

Drawer.displayName = "Drawer"

const DrawerTrigger = DrawerPrimitive.Trigger

const DrawerPortal = DrawerPrimitive.Portal

const DrawerClose = DrawerPrimitive.Close

const DrawerOverlay = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Overlay>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Overlay>
>(({ className, ...props }, ref) => (
	<DrawerPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-black/50", className)} {...props} />
))
DrawerOverlay.displayName = DrawerPrimitive.Overlay.displayName

const DrawerContent = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>
>(({ className, children, ...props }, ref) => (
	<DrawerPortal>
		<DrawerOverlay />
		<DrawerPrimitive.Content
			ref={ref}
			// DRAWER
			className={cn(
				"-mb-10 fixed inset-x-0 bottom-0 z-50 mx-auto flex h-auto max-h-[99%] w-full max-w-md flex-col place-items-center rounded-t-[1.25rem] bg-gradient-to-t from-white/[100%] to-white/[98.5%] pb-10 backdrop-blur-sm",
				className,
			)}
			{...props}
		>
			{children}
			{/* TODO: set these properties on each modal */}
			<DrawerPrimitive.Title aria-label={undefined} />
			<DrawerPrimitive.Description aria-description={undefined} />
		</DrawerPrimitive.Content>
	</DrawerPortal>
))
DrawerContent.displayName = "DrawerContent"

const DrawerHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn("grid gap-1.5 p-4 pb-3 text-center sm:text-left", className)} {...props} />
)
DrawerHeader.displayName = "DrawerHeader"

const DrawerFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn("mt-auto flex flex-col gap-2 p-4", className)} {...props} />
)
DrawerFooter.displayName = "DrawerFooter"

const DrawerTitle = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Title>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Title>
>(({ className, ...props }, ref) => (
	<DrawerPrimitive.Title
		ref={ref}
		className={cn("font-semibold text-lg leading-none tracking-tight", className)}
		{...props}
	/>
))
DrawerTitle.displayName = DrawerPrimitive.Title.displayName

const DrawerDescription = React.forwardRef<
	React.ElementRef<typeof DrawerPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Description>
>(({ className, ...props }, ref) => (
	<DrawerPrimitive.Description ref={ref} className={cn("text-sm text-zinc-500 ", className)} {...props} />
))
DrawerDescription.displayName = DrawerPrimitive.Description.displayName

export {
	Drawer,
	DrawerClose,
	DrawerContent,
	DrawerDescription,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerPortal,
	DrawerTitle,
	DrawerTrigger,
}
