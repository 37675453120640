import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import { CollectiveName } from "@web/components/collective/CollectiveName"
import { CollectivePrice } from "@web/components/collective/CollectivePrice"
import { CollectivePrize } from "@web/components/collective/CollectivePrize"
import { CollectiveAvatar } from "@web/components/shared/CollectiveAvatar"
import { Button } from "@web/components/ui/button"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useEntries } from "@web/hooks/queries/useEntries"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { formatPrice } from "@web/lib/formatters"
import { calculatePointsPrize, cn, getVotingPower, sharePage } from "@web/lib/utils"
import { Modal } from "@web/types"
import { Share } from "lucide-react"

const events = {
	share: "collective_page:share_button_click",
	trade: "collective_page:trade_button_click",
}

interface CollectiveHeaderProps {
	contractSlug: string
	collectiveSlug: string
	className?: string
}

export function CollectiveHeader({ contractSlug, collectiveSlug, className }: CollectiveHeaderProps) {
	const analytics = useAnalytics(events)
	const { openModal } = useModal()
	const { setShowAuthFlow } = useDynamicContext()
	const { isLoggedIn } = useLoginContext()

	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { tokenSlug } = contract ?? {}

	const {
		data: [token] = [],
	} = useTokens(tokenSlug)

	const {
		data: [collective] = [],
	} = useCollectives(contractSlug, collectiveSlug)
	const { name, state, isActive, voteCount, burntVoteCount, claimerVoteCount, fanVotes } = collective ?? {}

	const {
		data: [entries] = [],
	} = useEntries(contractSlug)
	const { entryCount } = entries ?? {}

	// Conditions for trading
	const canTrade = isLoggedIn && state === "active"
	const votingPower = getVotingPower(fanVotes, voteCount, burntVoteCount, claimerVoteCount)

	function handleClickShare() {
		analytics.share()
		sharePage(`${name} on Bracket.Game `, "Power To The Fans 📈")
	}

	function handleClickTrade(buy: boolean) {
		analytics.trade({ contractSlug, collectiveSlug, buy })
		if (state !== "active") return
		if (isLoggedIn) openModal(Modal.Trade, { buy })
		else setShowAuthFlow(true)
	}

	return (
		<>
			<div className={cn("relative flex items-center overflow-hidden", className)}>
				{/* Collective information */}
				<CollectiveAvatar size="big" collective={collective} className="my-1 ml-4" />

				<div className="-mt-[.28rem] ml-3 flex flex-col pb-1">
					<CollectiveName collective={collective} />
					<CollectivePrice token={token} collective={collective} />
				</div>

				{/* Share button */}
				<Button
					size="icon"
					variant="disappear"
					onClick={handleClickShare}
					className="absolute top-[1.75rem] right-[0.6rem] opacity-100 transition hover:opacity-100"
				>
					<Share className="text-black" />
				</Button>
			</div>

			<CollectivePrize
				contract={contract}
				collective={collective}
				entries={entries}
				className="mx-[1.16rem] mt-[.65rem] mb-[.05rem]"
			/>

			{/* Trade buttons */}
			<div className="mt-[.7rem] mb-[0rem] flex w-full gap-2 px-[1.1rem]">
				{/* Buy shares if collective is active and not a winner */}
				{Boolean(isActive) && ["pregame", "entry", "active"].includes(state) && (
					<Button
						onClick={() => handleClickTrade(true)}
						disabled={!canTrade}
						className={cn("grow p-0 transition", { "w-full": fanVotes === 0 })}
					>
						{fanVotes > 0 ? "Buy" : "Buy Shares"}
					</Button>
				)}

				{/* Sell shares if fan votes are greater than 0 */}
				{fanVotes > 0 && state === "active" && (
					<Button
						variant="sell"
						onClick={() => handleClickTrade(false)}
						className="grow bg-blue-light p-0 text-blue-primary hover:bg-[#f5f6fd] active:bg-[#f5f6fd] disabled:cursor-not-allowed disabled:opacity-50"
						disabled={!canTrade}
					>
						{isActive ? "Sell" : "Sell Shares"}
					</Button>
				)}

				{/* Display different win messages depending on if shares are held */}
				{Boolean(isActive) && ["postgame", "finished"].includes(state) && (
					<Button
						variant="default"
						disabled={true}
						className="grow bg-green-primary/10 p-0 text-green-primary disabled:cursor-not-allowed disabled:opacity-100"
					>
						{fanVotes > 0
							? `Game Won! You earned ${formatPrice((calculatePointsPrize(entryCount) * votingPower) / 100, 0)} pts`
							: "Game Complete"}
					</Button>
				)}

				{!isActive && fanVotes === 0 && (
					<Button
						variant="default"
						disabled={true}
						className="grow bg-gray-primary p-0 text-white disabled:cursor-not-allowed"
					>
						Team Eliminated
					</Button>
				)}
			</div>
		</>
	)
}
