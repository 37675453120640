import { Button } from "@web/components/ui/button"
import { Input } from "@web/components/ui/input"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { cn, removeLeadingZeros } from "@web/lib/utils"
import { Minus, Plus } from "lucide-react"
import { type KeyboardEvent, useEffect, useRef, useState } from "react"

interface ShareStepperProps {
	label?: string
	amount: number
	color: string
	min?: number
	max?: number
	isBuy: boolean
	isPlusEnabled: boolean
	isMinusEnabled: boolean
	onInputChange: (value: string) => void
	className?: string
}

const events = {
	step: "amount_stepper:step_button_click",
}

export function AmountStepper({
	label,
	amount,
	color,
	max,
	isBuy,
	isPlusEnabled,
	isMinusEnabled,
	onInputChange,
	className,
}: ShareStepperProps) {
	const analytics = useAnalytics(events)
	const [pressCount, setPressCount] = useState(0)
	const pressCountTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
	const [startedFromOne, setStartedFromOne] = useState(false)
	const [lastPress, setLastPress] = useState<"plus" | "minus">(isBuy ? "plus" : "minus")

	useEffect(() => {
		setStartedFromOne(amount === 1)
	}, [])

	function handleClickStep(action: "plus" | "minus") {
		analytics.step({ action })

		setLastPress(action)
		setPressCount((prev) => prev + 1)
		let stepAdjustment = 1
		if (pressCount >= 4) stepAdjustment = 5
		if (pressCount >= 8) stepAdjustment = 25
		if (pressCount >= 15) stepAdjustment = 50
		if (pressCount >= 21) stepAdjustment = 100
		if (pressCount >= 31) stepAdjustment = 250
		if (pressCount >= 43) stepAdjustment = 500

		const baseStepValue = action === "plus" ? 1 : -1
		const stepValue = baseStepValue * stepAdjustment
		let newValue = Math.max(0, amount + stepValue)
		newValue = Math.round(newValue / stepAdjustment) * stepAdjustment

		// Reset the press count after a delay
		onInputChange(String(newValue))
		clearTimeout(pressCountTimeout.current as ReturnType<typeof setTimeout>)
		pressCountTimeout.current = setTimeout(() => {
			setPressCount(0)
		}, 300)
	}

	function handleInputChange(value: string) {
		onInputChange(removeLeadingZeros(value))
	}

	function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
		if (amount === 0) {
			if (event.key === "0") {
				event.preventDefault()
			} else if (!Number.isNaN(Number(event.key))) {
				event.currentTarget.value = ""
			}
		}
	}

	function handleIncrementStep(increment: number) {
		let newValue: number
		if (startedFromOne && amount === 1) {
			newValue = lastPress === "plus" ? increment : -increment
		} else {
			const stepValue = lastPress === "plus" ? increment : -increment
			newValue = Math.max(0, amount + stepValue)
		}
		onInputChange(String(newValue))
		setStartedFromOne(false)
	}

	return (
		<div className={`group w-full ${amount === 0 ? "opacity-80" : "opacity-100"}`}>
			<div
				className={cn(
					"mt-4 flex w-full justify-between rounded-2xl py-0 ring-1 ring-gray-light transition-all duration-300 hover:ring-gray-primary",
					{
						"ring-blue-primary": isBuy && (isPlusEnabled || isMinusEnabled),
						"hover:ring-blue-primary": isBuy,
						"ring-[.09rem] active:ring-blue-primary": isBuy && pressCount < 4,
						"ring-[.12rem] active:ring-blue-primary": isBuy && pressCount >= 4,
						"ring-[.15rem] active:ring-blue-dark": isBuy && pressCount >= 8,
						"ring-[.18rem] active:ring-blue-dark": isBuy && pressCount >= 14,
						"transition-duration-6000": isBuy && pressCount >= 8, // Slower transition back to primary blue
						"ring-red-primary": !isBuy && (isPlusEnabled || isMinusEnabled),
						"hover:ring-red-primary": !isBuy,
						"ring-[.09rem] active:ring-red-primary": !isBuy && pressCount < 4,
						"ring-[.12rem] active:ring-red-primary": !isBuy && pressCount >= 4,
						"ring-[.15rem] active:ring-red-dark": !isBuy && pressCount >= 8,
						"ring-[.18rem] active:ring-red-dark": !isBuy && pressCount >= 14,
					},
					className,
				)}
			>
				<Button
					size="icon"
					variant="stepper"
					disabled={amount === 0}
					onClick={() => handleClickStep("minus")}
					className="my-[.6rem] ml-[.62rem] h-[3.3rem] w-[3.3rem] shrink-0 rounded-[.5rem] text-black transition active:scale-[96%] active:opacity-80 disabled:cursor-not-allowed disabled:opacity-25"
				>
					<Minus />
				</Button>

				<div className="relative flex w-full flex-col items-center justify-center">
					<label className="z-10 mt-[.55rem] mb-[.1rem] ml-[.13rem] text-[.8125rem] text-gray-primary" htmlFor="amount">
						{label}
					</label>
					<Input
						id="amount"
						type="number"
						pattern="[0-9]*"
						inputMode="numeric"
						value={Number.isNaN(amount) ? "0" : amount.toString()}
						min={1}
						max={max}
						onChange={(e) => handleInputChange(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e)}
						className={cn(
							"-mt-[.2rem] mb-[.3rem] w-full border-none bg-none p-0 text-center font-semibold text-[1.8rem] italic",
							{ "opacity-85": amount === 0 },
						)}
						style={{ color }}
					/>
				</div>

				<Button
					size="icon"
					variant="stepper"
					disabled={!isPlusEnabled}
					onClick={() => handleClickStep("plus")}
					className="my-[.6rem] mr-[.62rem] h-[3.3rem] w-[3.3rem] shrink-0 rounded-[.5rem] text-black transition active:scale-[96%] active:opacity-80 disabled:cursor-not-allowed disabled:opacity-25"
				>
					<Plus />
				</Button>
			</div>

			{/* Super Stepper */}
			<div className="mt-[.65rem] flex w-full justify-between">
				{["5", "10", "50", "100"].map((step) => (
					<Button
						variant="stepper"
						key={step}
						onClick={() => handleIncrementStep(Number(step))}
						className={`mx-[.15rem] h-[2.05rem] ${!isBuy ? amount < Number(step) : !isPlusEnabled ? "opacity-50" : ""} transition active:scale-[98.5%] disabled:cursor-not-allowed `}
						aria-label={`${!isBuy ? "Decrement" : "Increment"} by ${step}`}
						disabled={!isBuy ? amount < Number(step) : !isPlusEnabled}
					>
						{lastPress === "plus" ? `+${step}` : `-${step}`}
					</Button>
				))}
			</div>
		</div>
	)
}
