import type { GameState } from "@api/types"
import Arrows from "@web/assets/arrows.svg?react"
import Checks from "@web/assets/checks.svg?react"
import Clock from "@web/assets/clock.svg?react"
import Pause from "@web/assets/pause.svg?react"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import dayjs from "@web/lib/dayjs"
import { cn } from "@web/lib/utils"
import { type Contract, Modal } from "@web/types"
import { ChevronDown } from "lucide-react"
import { useEffect, useState } from "react"
import { OnlineIndicator } from "../shared/OnlineIndicator"

interface BannerState {
	backgroundColor: string
	bannerText: string
	icon: React.FC<React.SVGProps<SVGSVGElement>>
	iconColor: string
	textColor: string
	gradientOverlay?: string
	ring?: string // Added ring property
}

// NOTE: Must use named colors here, as the style is applied via a class name
const BANNERS: Record<GameState, BannerState> = {
	pregame: {
		backgroundColor: "bg-blue-light",
		bannerText: "Trading Opens Soon",
		icon: Clock,
		iconColor: "text-[#87A3F7]",
		textColor: "text-blue-primary",
		ring: "ring-1 ring-inset ring-blue-primary/[12%]",
	},
	entry: {
		backgroundColor: "bg-blue-light",
		bannerText: "Trading Opens Soon",
		icon: Clock,
		iconColor: "text-[#87A3F7]",
		textColor: "text-blue-primary",
		ring: "ring-1 ring-inset ring-blue-primary/[12%]",
	},
	active: {
		backgroundColor: "bg-blue-secondary",
		bannerText: "View Activity",
		icon: Arrows,
		iconColor: "text-[#CDDFFF]",
		textColor: "text-white",
		ring: "ring-1 ring-inset ring-blue-primary/[20%]",
	},
	verifying: {
		backgroundColor: "bg-blue-primary",
		bannerText: "Verifying results...",
		icon: Checks,
		iconColor: "text-[#CDDFFF]",
		textColor: "text-white",
		gradientOverlay: "bg-blue-primary/[100%] bg-gradient-to-b from-[#7FA4FF]/10 to-blue-brand/50",
	},
	postgame: {
		backgroundColor: "bg-green-secondary",
		bannerText: "Prize Pool distributed",
		icon: Checks,
		iconColor: "text-white",
		textColor: "text-white",
	},
	finished: {
		backgroundColor: "bg-blue-dark",
		bannerText: "Bracket ended",
		icon: Clock,
		iconColor: "text-white",
		textColor: "text-white",
	},
	paused: {
		backgroundColor: "bg-gray-primary",
		bannerText: "Trading is paused",
		icon: Pause,
		iconColor: "text-white",
		textColor: "text-white",
	},
	hidden: {
		backgroundColor: "bg-gray-primary",
		bannerText: "Trading is paused",
		icon: Pause,
		iconColor: "text-white",
		textColor: "text-white",
	},
}

function calculateTimeLeft(startDate: string | null) {
	if (!startDate) return "Trading Opens Soon"

	const now = dayjs()
	const start = dayjs(startDate)
	const diff = start.diff(now)

	if (diff > 0) {
		const dur = dayjs.duration(diff)
		return `Starts in ${dur.days()}d ${dur.hours()}h ${dur.minutes()}m ${dur.seconds()}s`
	}

	return "Trading Opens Soon"
}

function calculateTimeSince(_endDate: string | null) {
	return "This Bracket has ended"
	// if (!endDate) return "This Bracket has ended"

	// const now = dayjs()
	// const end = dayjs(endDate)
	// const diff = now.diff(end)

	// if (diff > 0) {
	// 	const dur = dayjs.duration(diff)
	// 	return `Bracket ended ${Math.round(dur.asDays())}d ago`
	// }

	// return "This Bracket has ended"
}

export function calculateTimeProgress(startDate: string | null, endDate: string | null) {
	if (!startDate || !endDate) return 0

	const start = dayjs(startDate)
	const end = dayjs(endDate)
	const now = dayjs()

	// Ensure the current time is between start and end dates
	if (now.isBefore(start)) return 0
	if (now.isAfter(end)) return 100

	const totalDuration = end.diff(start)
	const elapsedDuration = now.diff(start)

	const progress = (elapsedDuration / totalDuration) * 100
	return Math.min(Math.max(progress, 0), 100)
}

const events = { banner: "holdings_banner:banner_button_click" }

interface BannerProps {
	contract: Contract
	className?: string
}

export function Banner({ contract, className }: BannerProps) {
	const analytics = useAnalytics(events)
	const { openModal } = useModal()
	const { state, bannerOverride, startDate, endDate } = contract ?? {}

	const banner = BANNERS[state as GameState] ?? undefined
	const Icon = banner?.icon
	const isCountdown = !!startDate && ["pregame", "entry"].includes(state)
	const isCountup = !!endDate && state === "finished"

	function handleClickBanner() {
		analytics.banner()
		openModal(Modal.Activity)
	}

	const [timeLeft, setTimeLeft] = useState<string | undefined>(calculateTimeLeft(startDate))
	const timeSince = calculateTimeSince(endDate)
	const timeProgress = calculateTimeProgress(startDate, endDate)

	// Update the time left every second
	useEffect(() => {
		if (startDate) {
			setTimeLeft(calculateTimeLeft(startDate))
			const timer = setInterval(() => {
				setTimeLeft(calculateTimeLeft(startDate))
			}, 1000)

			return () => clearInterval(timer)
		}
	}, [startDate])

	if (!banner) return null

	return (
		<Button
			variant="banner"
			onClick={handleClickBanner}
			className={cn(
				"relative overflow-hidden border-[#527AFE]/40 font-semibold text-[.875rem] transition-all",
				{
					"shadow-pregame": ["pregame", "entry"].includes(state), // Conditional class for pregame shadow
					"shadow-active": !["pregame", "entry"].includes(state), // Default inner shadow for other states
				},
				`${banner.backgroundColor}`,
				`${banner.gradientOverlay}`, // Added gradient overlay class
				`${banner.ring}`, // Added ring class
				className,
			)}
		>
			{/* Banner Icon */}
			{state === "active" && <OnlineIndicator backgroundColor="#2ed395" className="z-10" />}
			{state !== "active" && (
				<Icon className={cn("z-10 mt-[.02rem] mr-[.35rem] size-[.95rem]", `${banner.iconColor}`)} />
			)}

			{/* Secondary progress bar is drawn first */}
			<div
				className={cn(
					"-left-1 -skew-x-12 absolute hidden h-full animate-pulseMove animate-pulseMoveOpacity rounded-tr-[.18rem] rounded-br-[.18rem] pl-1",
					{
						"block bg-[#527AFE]": state === "active",
					},
				)}
				style={{ width: `${timeProgress + 1}%` }}
			/>

			{/* Primary progress bar overlaps the secondary */}
			<div
				className={cn("-left-1 -skew-x-12 absolute hidden h-full rounded-tr-[.18rem] rounded-br-[.18rem] pl-1", {
					"block bg-blue-primary/[100%] bg-gradient-to-b from-[#7FA4FF]/10 to-blue-brand/50": state === "active",
				})}
				style={{ width: `${timeProgress}%` }}
			/>

			{/* Default banner message */}
			{!bannerOverride && !isCountdown && !isCountup && (
				<span className={cn("z-10 ", `${banner.textColor}`)}>{banner.bannerText}</span>
			)}

			{/* Show override message if it's defined */}
			{!!bannerOverride && <span className={cn("z-10", `${banner.textColor}`)}>{bannerOverride}</span>}

			{/* Show a countdown in pregame state if startDate is defined */}
			{!bannerOverride && isCountdown && <span className={cn("z-10", `${banner.textColor}`)}>{timeLeft}</span>}

			{/* Show a countup in finished state if endDate is defined */}
			{!bannerOverride && isCountup && <span className={cn("z-10", `${banner.textColor}`)}>{timeSince}</span>}

			<ChevronDown className={cn("z-10 mt-[.03rem] ml-[.04rem] size-5", `${banner.iconColor}`)} />
		</Button>
	)
}
