import { PercentChange } from "@web/components/shared/PercentChange"
import { formatPriceString } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import type { Collective, Token } from "@web/types"

interface CollectivePriceProps {
	token: Token
	collective: Collective
	className?: string
}

export function CollectivePrice({ token, collective, className }: CollectivePriceProps) {
	const { ticker } = token ?? {}
	const { collectivePrice, decimals, state, isActive, percentChange } = collective ?? {}

	return (
		<div className={cn("relative flex w-fit items-start", className)}>
			<span
				className={cn("font-semibold text-4xl leading-8", {
					"text-gray-primary": ["pregame", "entry"].includes(state),
				})}
			>
				{!["pregame", "entry"].includes(state) ? formatPriceString(collectivePrice ?? "0", 2, decimals) : "0.0"}
			</span>

			<span className="ml-[.22rem] text-gray-primary text-xs uppercase tracking-[.01rem]">{ticker}</span>
			<PercentChange percent={percentChange} state={state} isActive={isActive} className="ml-1 font-medium text-xs" />
		</div>
	)
}
