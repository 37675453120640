import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import ActivityModal from "@web/components/modals/ActivityModal"
import DepositModal from "@web/components/modals/DepositModal"
import EntryModal from "@web/components/modals/EntryModal"
import FAQsModal from "@web/components/modals/FAQsModal"
import FanModal from "@web/components/modals/FanModal"
import FundModal from "@web/components/modals/FundModal"
import NavModal from "@web/components/modals/NavModal"
import OffboardModal from "@web/components/modals/OffboardModal"
import OnboardModal from "@web/components/modals/OnboardModal"
import PrizeModal from "@web/components/modals/PrizeModal"
import ProfileModal from "@web/components/modals/ProfileModal"
import RedeemModal from "@web/components/modals/RedeemModal"
import SocialModal from "@web/components/modals/SocialModal"
import TradeModal from "@web/components/modals/TradeModal"
import WithdrawModal from "@web/components/modals/WithdrawModal"
import { Drawer } from "@web/components/ui/drawer"
import { useModal } from "@web/hooks/useModal"
import { Modal } from "@web/types"

export default function ModalController() {
	const { activeModal, closeModal } = useModal()
	const { showAuthFlow } = useDynamicContext()

	function isActive(modal: Modal) {
		if (showAuthFlow) return false
		return activeModal === modal
	}

	function safeCloseModal(modal: Modal) {
		if (activeModal === modal && !showAuthFlow) {
			return (open: boolean) => closeModal(open)
		}

		return (_: boolean) => true
	}

	return (
		<>
			{/* Nav menu is styled different than traditional modals */}
			<Drawer
				open={isActive(Modal.Nav)}
				onOpenChange={safeCloseModal(Modal.Nav)}
				direction="left"
				shouldScaleBackground={false}
			>
				<NavModal />
			</Drawer>

			<Drawer open={isActive(Modal.Activity)} onOpenChange={safeCloseModal(Modal.Activity)}>
				<ActivityModal />
			</Drawer>
			<Drawer open={isActive(Modal.Deposit)} onOpenChange={safeCloseModal(Modal.Deposit)}>
				<DepositModal />
			</Drawer>
			<Drawer open={isActive(Modal.Entry)} onOpenChange={safeCloseModal(Modal.Entry)}>
				<EntryModal />
			</Drawer>
			<Drawer open={isActive(Modal.Fan)} onOpenChange={safeCloseModal(Modal.Fan)}>
				<FanModal />
			</Drawer>
			<Drawer open={isActive(Modal.Fund)} onOpenChange={safeCloseModal(Modal.Fund)}>
				<FundModal />
			</Drawer>
			<Drawer open={isActive(Modal.FAQs)} onOpenChange={safeCloseModal(Modal.FAQs)}>
				<FAQsModal />
			</Drawer>
			<Drawer open={isActive(Modal.Offboard)} onOpenChange={safeCloseModal(Modal.Offboard)}>
				<OffboardModal />
			</Drawer>
			<Drawer open={isActive(Modal.Onboard)} onOpenChange={safeCloseModal(Modal.Onboard)}>
				<OnboardModal />
			</Drawer>
			<Drawer open={isActive(Modal.Prize)} onOpenChange={safeCloseModal(Modal.Prize)}>
				<PrizeModal />
			</Drawer>
			<Drawer open={isActive(Modal.Profile)} onOpenChange={safeCloseModal(Modal.Profile)}>
				<ProfileModal />
			</Drawer>
			<Drawer open={isActive(Modal.Redeem)} onOpenChange={safeCloseModal(Modal.Redeem)}>
				<RedeemModal />
			</Drawer>
			<Drawer open={isActive(Modal.Social)} onOpenChange={safeCloseModal(Modal.Social)}>
				<SocialModal />
			</Drawer>
			<Drawer open={isActive(Modal.Trade)} onOpenChange={safeCloseModal(Modal.Trade)}>
				<TradeModal />
			</Drawer>
			<Drawer open={isActive(Modal.Withdraw)} onOpenChange={safeCloseModal(Modal.Withdraw)}>
				<WithdrawModal />
			</Drawer>
		</>
	)
}
