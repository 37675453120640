import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@radix-ui/react-accordion"
import { Minus, Plus } from "lucide-react"

interface FAQsProps {
	faqs?: { question: string; answer: string }[]
}

export function FAQs({ faqs }: FAQsProps) {
	return (
		<Accordion type="multiple">
			{faqs?.map((faq) => (
				<AccordionItem key={faq.question} value={faq.question} className="border-white/30 border-b pb-5">
					<AccordionTrigger className="group mt-4 flex w-full items-start justify-between text-left font-medium text-md leading-snug">
						{faq.question}
						<Plus className="mt-1.5 size-4 stroke-[3] stroke-white group-data-[state=open]:hidden" />
						<Minus className="mt-1.5 size-4 stroke-[3] stroke-white group-data-[state=closed]:hidden group-data-[state=open]:opacity-75" />
					</AccordionTrigger>

					<AccordionContent className="mt-4 whitespace-pre-wrap rounded-[12px] bg-white/20 px-5 py-4">
						{/* biome-ignore lint/security/noDangerouslySetInnerHtml: FAQ answers can be dynamically set from db */}
						<span dangerouslySetInnerHTML={{ __html: faq.answer }} />
					</AccordionContent>
				</AccordionItem>
			))}
		</Accordion>
	)
}
