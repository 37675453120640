import { createFileRoute, useParams } from "@tanstack/react-router"
import { Banner } from "@web/components/contract/Banner"
import { ContractTabs } from "@web/components/contract/ContractTabs"
import { Loading } from "@web/components/shared/Loading"
import { CollectivesTable } from "@web/components/tables/CollectivesTable"
import { collectivesQueryOptions, useCollectives } from "@web/hooks/queries/useCollectives"
import { contractsQueryOptions, useContracts } from "@web/hooks/queries/useContracts"
import { DEFAULT_CONTRACT } from "@web/lib/constants"

export const Route = createFileRoute("/")({
	component: IndexRoute,
	loader: ({ context: { queryClient } }) => {
		queryClient.ensureQueryData(contractsQueryOptions(queryClient, DEFAULT_CONTRACT))
		queryClient.ensureQueryData(collectivesQueryOptions(queryClient, DEFAULT_CONTRACT))
	},
})

function IndexRoute() {
	const { contractSlug = DEFAULT_CONTRACT } = useParams({ strict: false })
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { data: collectives = [] } = useCollectives(contractSlug)

	if (!contract) return <Loading />

	return (
		<>
			<ContractTabs className="mt-[.92rem] mb-[.4rem]" />
			<Banner contract={contract} className="mx-[.9rem] mt-[.05rem] mb-[.3rem]" />
			<CollectivesTable collectives={collectives} />
		</>
	)
}
