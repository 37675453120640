import { queryOptions, useQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"

async function getStats(contractSlug: string) {
	const res = await client.api.contracts[":contractSlug"].stats.$get({ param: { contractSlug } })
	return await res.json()
}

function statsQueryOptions(contractSlug: string) {
	return queryOptions({
		queryKey: ["stats", contractSlug],
		queryFn: () => getStats(contractSlug),
		refetchInterval: DEFAULT_POLLING_INTERVAL * 2,
	})
}

export function useStats(contractSlug: string) {
	return useQuery(statsQueryOptions(contractSlug))
}
