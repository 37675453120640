import { Link } from "@tanstack/react-router"
import Logo from "@web/assets/logo.svg?react"
import { cn } from "@web/lib/utils"

interface TableEndProps {
	length: number
}

export function TableEnd({ length }: TableEndProps) {
	return (
		<tr>
			<td colSpan={length} className={cn("pt-[1.2rem] pb-[6rem]")}>
				<Link to="..">
					<Logo className="mx-auto h-[1.75rem] cursor-pointer text-blue-brand transition active:scale-[97%]" />
				</Link>
			</td>
		</tr>
	)
}
