import { Slot } from "@radix-ui/react-slot"
import { type VariantProps, cva } from "class-variance-authority"
import * as React from "react"

import { cn } from "@web/lib/utils"

const buttonVariants = cva(
	"inline-flex items-center justify-center whitespace-nowrap transition text-[.9375rem] font-semibold ring-offset-white disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default:
					"bg-blue-primary active:bg-blue-primary/85 hover:brightness-[103%] active:scale-[98.5%] transition-all text-white active:text-opacity-100",
				sell: "grow bg-[#F6F8FE] p-0 text-[.9375rem] font-semibold text-blue-primary hover:bg-[#f5f6fd] active:scale-[98.5%] transition-all active:bg-[#f5f6fd] disabled:cursor-not-allowed disabled:opacity-50",
				destructive: "bg-red-primary hover:bg-red-primary/90 active:bg-red-primary text-white active:text-opacity-90",
				outline:
					"border border-gray-light bg-gradient-to-t shadow-sm from-black/[2.5%] to-black/0 active:bg-[#ffffff]  active:shadow-none transition-all",
				secondary: "bg-zinc-100 text-zinc-900 hover:bg-zinc-100/80",
				ghost: "rounded-md hover:bg-blue-primary/5 hover:text-blue-primary",
				link: "text-blue-primary text-sm font-medium underline-offset-4 hover:underline",
				// Custom
				loading: "bg-gray-200 text-gray-900",
				stepper:
					"flex-1 px-4 py-1 text-sm font-medium rounded-md bg-[#f4f4f5] hover:bg-[#f4f4f5] active:bg-[#EFEFF0] select-none text-gray-primary hover:text-black active:text-black",
				banner: "transition-all hover:opacity-95 active:opacity-90 active:scale-[99%]",
				disappear: "bg-transparent transition-all duration-200 active:opacity-20",
			},
			size: {
				sm: "h-[2rem] py-[.4rem] text-sm rounded-[4px] px-[.7rem]",
				default: "h-[2.45rem] rounded-[7px] px-4 py-2",
				lg: "h-[3.43rem] rounded-[10px] px-8",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	},
)

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : "button"
		return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
	},
)
Button.displayName = "Button"

export { Button, buttonVariants }
