import { Flag } from "@web/components/shared/Flag"
import { Seed } from "@web/components/shared/Seed"
import type { Collective } from "@web/types"

interface CollectiveNameProps {
	collective: Collective
}

export function CollectiveName({ collective }: CollectiveNameProps) {
	const { fanbase, flagSrc, isActive, name, seed, state } = collective ?? {}

	return (
		<div className="flex max-w-[calc(100%-3.5rem)] items-center gap-1">
			{flagSrc && <Flag src={flagSrc} name={name} />}

			<span className="ml-[.15rem] whitespace-nowrap font-bold text-[.9375rem] text-gray-black italic">{fanbase}</span>
			{seed && (
				<Seed
					isActive={isActive}
					seed={seed}
					state={state}
					className="mt-[.05rem] ml-[.05rem] whitespace-nowrap text-[0.8125rem]"
				/>
			)}
		</div>
	)
}
