import type { QueryClient } from "@tanstack/react-query"
import { Outlet, ScrollRestoration, createRootRouteWithContext, useLocation } from "@tanstack/react-router"
import { zodSearchValidator } from "@tanstack/router-zod-adapter"
import { Footer } from "@web/components/layout/Footer"
import { Header } from "@web/components/layout/Header"
import ModalController from "@web/components/modals/ModalController"
import { Toaster } from "@web/components/ui/sonner"
import { usePrimaryChainSwitch } from "@web/hooks/usePrimaryChainSwitch"
import { Modal } from "@web/types"
import { usePostHog } from "posthog-js/react"
import { useEffect } from "react"
import { isAddress } from "viem"
import { z } from "zod"

interface RouterContext {
	queryClient: QueryClient
}

const searchSchema = z.object({
	buy: z.boolean().optional(),
	enter: z.boolean().optional(),
	fanId: z.string().refine(isAddress, "Invalid address").optional(),
	filter: z.enum(["activity", "holdings"]).optional(),
	modal: z.nativeEnum(Modal).optional(),
	page: z.number().optional(),
	price: z.number().optional(),
	quantity: z.number().optional(),
	rank: z.number().optional(),
	tokenSlug: z.string().optional(),
})

export const Route = createRootRouteWithContext<RouterContext>()({
	component: RootRoute,
	validateSearch: zodSearchValidator(searchSchema),
})

function RootRoute() {
	const posthog = usePostHog()
	const location = useLocation()

	// Ensure user is connected to correct chain
	usePrimaryChainSwitch()

	// TODO: Move pageview tracking to better location
	useEffect(() => {
		posthog.capture("$pageview", { $current_url: window.location.href })
		return () => {
			posthog.capture("$pageleave", { $current_url: window.location.href })
		}
	}, [location])

	// Hide splash screen once app is ready
	useEffect(() => {
		const splash = document.getElementById("splash")
		if (splash) splash.style.display = "none"
	}, [])

	return (
		<div className="mx-auto flex min-h-[100dvh] max-w-md flex-col border-[#ebf1f4] bg-white shadow-md">
			<Header />

			<main className="flex flex-col overflow-x-clip">
				<Outlet />
				<ScrollRestoration />
			</main>

			<Footer />
			<ModalController />
			<Toaster position="bottom-center" />
		</div>
	)
}
