import { queryOptions, useQuery } from "@tanstack/react-query"
import { DEFAULT_POLLING_INTERVAL } from "@web/lib/constants"
import { client } from "@web/lib/hono"
import type { Address } from "viem"

async function getFanActivity(fanId: Address) {
	const res = await client.api.fan[":fanId"].activity.$get({ param: { fanId } })
	return await res.json()
}

async function getContractActivity(contractSlug: string) {
	const res = await client.api.contracts[":contractSlug"].activity.$get({ param: { contractSlug } })
	return await res.json()
}

async function getCollectiveActivity(contractSlug: string, collectiveSlug: string) {
	const res = await client.api.contracts[":contractSlug"].collectives[":collectiveSlug"].activity.$get({
		param: { contractSlug, collectiveSlug },
	})
	return await res.json()
}

function activityQueryOptions(contractSlug?: string, collectiveSlug?: string, fanId?: Address) {
	return queryOptions({
		queryKey: ["activity", contractSlug, collectiveSlug, fanId],
		queryFn: () =>
			fanId
				? getFanActivity(fanId)
				: contractSlug && collectiveSlug
					? getCollectiveActivity(contractSlug, collectiveSlug)
					: contractSlug
						? getContractActivity(contractSlug)
						: undefined,
		refetchInterval: DEFAULT_POLLING_INTERVAL,
	})
}

export function useActivity(contractSlug?: string, collectiveSlug?: string, fanId?: Address) {
	return useQuery(activityQueryOptions(contractSlug, collectiveSlug, fanId))
}
