import type { GameState } from "@api/types"
import { cn } from "@web/lib/utils"

interface PercentChangeProps {
	percent: string
	state: GameState
	isActive?: boolean
	className?: string
}

export function PercentChange({ percent, state, isActive = true, className }: PercentChangeProps) {
	const value = Number.parseFloat(percent)

	if (["pregame", "entry", "postgame", "finished"].includes(state) || value === 0) {
		return (
			<span
				className={cn(
					"font-mono",
					{ "text-gray-primary/50": !isActive, "text-green-secondary/50": isActive },
					className,
				)}
			>
				0.00%
			</span>
		)
	}

	return (
		<span
			className={cn("font-mono", { "text-green-secondary": value > 0 }, { "text-red-primary": value < 0 }, className)}
		>
			{value > 0 && "+"}
			{value.toFixed(Math.abs(value) > 1000 ? 0 : 2)}%
		</span>
	)
}
