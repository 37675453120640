import { Loading } from "@web/components/shared/Loading"
import { ownerColumns } from "@web/components/tables/columns/owners"
import { DataTable } from "@web/components/ui/data-table"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useOwners } from "@web/hooks/queries/useOwners"

interface OwnersTableProps {
	contractSlug: string
	collectiveSlug: string
}

export function OwnersTable({ contractSlug, collectiveSlug }: OwnersTableProps) {
	const {
		data: [contract] = [],
	} = useContracts(contractSlug)
	const { prizeModalInfo } = contract ?? {}
	const { data: owners = [], isLoading } = useOwners(contractSlug, collectiveSlug)

	if (isLoading)
		return (
			<Loading className="-mt-[5.7rem] pointer-events-none absolute inset-0 z-50 flex h-screen items-center justify-center" />
		)

	return (
		<DataTable
			columns={ownerColumns}
			data={owners}
			initialSort={[{ id: "fanVotes", desc: true }]}
			meta={{ prizeModalInfo, showTableEnd: true }}
		/>
	)
}
