import type { Address } from "viem"

export function getCurveVotesPrice(denominator: number, supply: number, amount: number) {
	const firstTerm = ((supply + 1) * 10 ** 6) / denominator
	const lastTerm = ((supply + amount) * 10 ** 6) / denominator

	return (amount * (firstTerm + lastTerm)) / 2 / 10 ** 6
}

export const BRACKET_ERRORS = {
	"0x6697b232": "AccessControlBadConfirmation",
	"0xe2517d3f": "AccessControlUnauthorizedAccount",
	"0x9996b315": "AddressEmptyCode",
	"0xcd786059": "AddressInsufficientBalance",
	"0xa24a13a6": "ArrayLengthMismatch",
	"0x8b5d7e76": "CollectiveNotDistributed",
	"0x1425ea42": "FailedInnerCall",
	"0x60900ed6": "InsufficientVotes",
	"0xf92ee8a9": "InvalidInitialization",
	"0xd7e6bcf8": "NotInitializing",
	"0x1e4fbdf7": "OwnableInvalidOwner",
	"0x118cdaa7": "OwnableUnauthorizedAccount",
	"0x5274afe7": "SafeERC20FailedOperation",
	"0xdffc1f06": "SeasonNotTradeable",
	"0x7dd37f70": "Slippage",
	"0x1f2a2005": "ZeroAmount",
}

// TODO: upgrade viem to v2. then figure out how to infer the type from ABI
export interface BracketGameEvents {
	Trade: {
		eventName: "Trade"
		args: {
			fan: Address
			collective: Address
			isBuy: boolean
			voteAmount: bigint
			fanVotes: bigint
			supply: bigint
			price: {
				base: bigint
				protocolFee: bigint
				collectiveFee: bigint
				poolFee: bigint
				totalFee: bigint
				total: bigint
				perVote: bigint
			}
		}
	}
	Redeem: {
		eventName: "Redeem"
		args: {
			fan: Address
			collective: Address
			voteAmount: bigint
			fanVotes: bigint
			supply: bigint
			value: bigint
		}
	}
	TransferVotes: {
		eventName: "TransferVotes"
		args: {
			fan: Address
			collective: Address
			voteAmount: bigint
			fanVotes: bigint
			supply: bigint
			value: bigint
			fanReceiver: Address
		}
	}
}

export const BracketTopics = {
	Trade: "0xe873a17232e37068428bf2c007a4eaca0feee5c2650cc18e10bc4b49c3a73a46",
	Redeem: "0x8caf04742286d017f9ac3924388e188c73e6e5094311c5e59a61a7ef86dda8bf",
	Transfer: "0x29b09989de92eb1d53d56befaa63adf579dc84fde0f734847ac356917551ba66",
}

export const BRACKET_ABI = [
	{
		inputs: [],
		name: "AccessControlBadConfirmation",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
			{
				internalType: "bytes32",
				name: "neededRole",
				type: "bytes32",
			},
		],
		name: "AccessControlUnauthorizedAccount",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "target",
				type: "address",
			},
		],
		name: "AddressEmptyCode",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "AddressInsufficientBalance",
		type: "error",
	},
	{
		inputs: [],
		name: "ArrayLengthMismatch",
		type: "error",
	},
	{
		inputs: [],
		name: "CollectiveNotDistributed",
		type: "error",
	},
	{
		inputs: [],
		name: "FailedInnerCall",
		type: "error",
	},
	{
		inputs: [],
		name: "InsufficientVotes",
		type: "error",
	},
	{
		inputs: [],
		name: "InvalidInitialization",
		type: "error",
	},
	{
		inputs: [],
		name: "NotInitializing",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "owner",
				type: "address",
			},
		],
		name: "OwnableInvalidOwner",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "OwnableUnauthorizedAccount",
		type: "error",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
		],
		name: "SafeERC20FailedOperation",
		type: "error",
	},
	{
		inputs: [],
		name: "SeasonNotTradeable",
		type: "error",
	},
	{
		inputs: [],
		name: "Slippage",
		type: "error",
	},
	{
		inputs: [],
		name: "ZeroAmount",
		type: "error",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "exitRound",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "winningPct",
				type: "uint256",
			},
		],
		name: "DistributeCollectiveWinnings",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "season",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "prizePool",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "distributedPool",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "endBlock",
				type: "uint256",
			},
		],
		name: "DistributeSeason",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "season",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "IncreasePrizePool",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint64",
				name: "version",
				type: "uint64",
			},
		],
		name: "Initialized",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "round",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "season",
				type: "uint256",
			},
		],
		name: "OracleExitRoundVerified",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "bool",
				name: "isVerified",
				type: "bool",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "season",
				type: "uint256",
			},
		],
		name: "OracleWinningsVerified",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "previousOwner",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "OwnershipTransferred",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "bool",
				name: "buyAndSell",
				type: "bool",
			},
		],
		name: "Paused",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "fan",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "voteAmount",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "fanVotes",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "supply",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "value",
				type: "uint256",
			},
		],
		name: "Redeem",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "previousAdminRole",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "bytes32",
				name: "newAdminRole",
				type: "bytes32",
			},
		],
		name: "RoleAdminChanged",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
		],
		name: "RoleGranted",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				indexed: true,
				internalType: "address",
				name: "account",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "sender",
				type: "address",
			},
		],
		name: "RoleRevoked",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "string",
				name: "fanbase",
				type: "string",
			},
		],
		name: "SetCollectiveFanbase",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: "uint256",
				name: "poolPct",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "collectivePct",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "protocolPct",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "protocolDestination",
				type: "address",
			},
		],
		name: "SetFeeStructure",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "fan",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "bool",
				name: "isBuy",
				type: "bool",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "voteAmount",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "fanVotes",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "supply",
				type: "uint256",
			},
			{
				components: [
					{
						internalType: "uint256",
						name: "base",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "protocolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "collectiveFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "poolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "totalFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "total",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "perVote",
						type: "uint256",
					},
				],
				indexed: false,
				internalType: "struct BG_Beta.FullPrice",
				name: "price",
				type: "tuple",
			},
		],
		name: "Trade",
		type: "event",
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: "address",
				name: "fan",
				type: "address",
			},
			{
				indexed: true,
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "voteAmount",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "fanVotes",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "uint256",
				name: "supply",
				type: "uint256",
			},
			{
				indexed: false,
				internalType: "address",
				name: "fanSender",
				type: "address",
			},
		],
		name: "TransferVotes",
		type: "event",
	},
	{
		inputs: [],
		name: "CLAIMER_ROLE",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "DEFAULT_ADMIN_ROLE",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "MANAGER_ROLE",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "ORACLE_ROLE",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "fan",
				type: "address",
			},
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
		],
		name: "balanceOf",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "maxValue",
				type: "uint256",
			},
		],
		name: "buyVotes",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "claimerAccount",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		name: "collectives",
		outputs: [
			{
				internalType: "string",
				name: "name",
				type: "string",
			},
			{
				internalType: "uint256",
				name: "supply",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "burnt",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "currentSeason",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "curveDenominator",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "_collectives",
				type: "address[]",
			},
		],
		name: "distributeSeasonWinnings",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "feeStructure",
		outputs: [
			{
				internalType: "uint256",
				name: "poolPct",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "collectivePct",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "protocolPct",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "protocolDestination",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "getBuyPrice",
		outputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "base",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "protocolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "collectiveFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "poolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "totalFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "total",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "perVote",
						type: "uint256",
					},
				],
				internalType: "struct BG_Beta.FullPrice",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "getRedeemPrice",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
		],
		name: "getRoleAdmin",
		outputs: [
			{
				internalType: "bytes32",
				name: "",
				type: "bytes32",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "getSellPrice",
		outputs: [
			{
				components: [
					{
						internalType: "uint256",
						name: "base",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "protocolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "collectiveFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "poolFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "totalFee",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "total",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "perVote",
						type: "uint256",
					},
				],
				internalType: "struct BG_Beta.FullPrice",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "grantRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "hasRole",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "increasePrizePool",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_curveDenominator",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "_stableCoin",
				type: "address",
			},
		],
		name: "initialize",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "owner",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [],
		name: "prizePool",
		outputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "round",
				type: "uint256",
			},
		],
		name: "receiveVerifiedCollectiveExitRound",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bool",
				name: "isVerified",
				type: "bool",
			},
		],
		name: "receiveVerifiedTotalWinnings",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "redeemVotes",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "renounceOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				internalType: "address",
				name: "callerConfirmation",
				type: "address",
			},
		],
		name: "renounceRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes32",
				name: "role",
				type: "bytes32",
			},
			{
				internalType: "address",
				name: "account",
				type: "address",
			},
		],
		name: "revokeRole",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "seasonNow",
		outputs: [
			{
				components: [
					{
						internalType: "bool",
						name: "isDistributed",
						type: "bool",
					},
					{
						internalType: "bool",
						name: "isVerified",
						type: "bool",
					},
					{
						internalType: "uint256",
						name: "startBlock",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "endBlock",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "prizePool",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "distributedPool",
						type: "uint256",
					},
					{
						internalType: "uint256",
						name: "roundsN",
						type: "uint256",
					},
					{
						internalType: "uint256[]",
						name: "winningBreakdown",
						type: "uint256[]",
					},
				],
				internalType: "struct BG_Beta.SeasonView",
				name: "",
				type: "tuple",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "",
				type: "uint256",
			},
		],
		name: "seasons",
		outputs: [
			{
				internalType: "bool",
				name: "isDistributed",
				type: "bool",
			},
			{
				internalType: "bool",
				name: "isVerified",
				type: "bool",
			},
			{
				internalType: "uint256",
				name: "startBlock",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "endBlock",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "prizePool",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "distributedPool",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "roundsN",
				type: "uint256",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "collective",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "minValue",
				type: "uint256",
			},
		],
		name: "sellVotes",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_claimerAccount",
				type: "address",
			},
		],
		name: "setClaimerAccount",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "_collectives",
				type: "address[]",
			},
			{
				internalType: "string[]",
				name: "fanbases",
				type: "string[]",
			},
		],
		name: "setCollectivesFanbases",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_denominator",
				type: "uint256",
			},
		],
		name: "setCurve",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "poolPct",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "collectivePct",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "protocolPct",
				type: "uint256",
			},
			{
				internalType: "address",
				name: "protocolDestination",
				type: "address",
			},
		],
		name: "setFeeStructure",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "uint256",
				name: "_season",
				type: "uint256",
			},
			{
				internalType: "uint256",
				name: "roundsN",
				type: "uint256",
			},
			{
				internalType: "uint256[]",
				name: "winningBreakdown",
				type: "uint256[]",
			},
		],
		name: "setSeason",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "_stableCoin",
				type: "address",
			},
		],
		name: "setStableCoin",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bool",
				name: "setPause",
				type: "bool",
			},
		],
		name: "setTxPause",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "stableCoin",
		outputs: [
			{
				internalType: "address",
				name: "",
				type: "address",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "bytes4",
				name: "interfaceId",
				type: "bytes4",
			},
		],
		name: "supportsInterface",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "newOwner",
				type: "address",
			},
		],
		name: "transferOwnership",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address[]",
				name: "_collectives",
				type: "address[]",
			},
			{
				internalType: "address[]",
				name: "_receivers",
				type: "address[]",
			},
			{
				internalType: "uint256[]",
				name: "_amounts",
				type: "uint256[]",
			},
		],
		name: "transferVotes",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
	{
		inputs: [],
		name: "txPaused",
		outputs: [
			{
				internalType: "bool",
				name: "",
				type: "bool",
			},
		],
		stateMutability: "view",
		type: "function",
	},
	{
		inputs: [
			{
				internalType: "address",
				name: "token",
				type: "address",
			},
			{
				internalType: "uint256",
				name: "amount",
				type: "uint256",
			},
		],
		name: "withdrawToken",
		outputs: [],
		stateMutability: "nonpayable",
		type: "function",
	},
] as const
