import { cn } from "@web/lib/utils"
import type { PropsWithChildren } from "react"

interface TransparentBoxProps extends PropsWithChildren {
	className?: string
	innerClassName?: string
}

export function TransparentBox({ children, className, innerClassName }: TransparentBoxProps) {
	return (
		<div
			className={cn("rounded-lg p-[0.05rem]", className)}
			style={{
				background: "linear-gradient(180deg, rgba(51, 47, 255, 0.05) 0%, rgba(255, 255, 255, 0.2) 100%)",
			}}
		>
			<div
				className={cn("relative flex rounded-lg p-[0.6rem]", innerClassName)}
				style={{
					background: "linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.11) 100%)",
					boxShadow: "0 0px 1px rgba(255, 255, 255, 0.9), 0 0px 0px rgba(255, 255, 255, 0.100)",
				}}
			>
				{children}
			</div>
		</div>
	)
}
